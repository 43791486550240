<template>
  <div :style="{ 'background-color': waiverConfigurations.background_color, 'overflow': 'auto',
  'position' : 'absolute',
  'top' : '0px',
    'right' : '0px',
  'bottom':'0px',
  'left':'0px'}">
    <link v-if="!loading" rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + waiverConfigurations.font_family">
    <link v-if="!loading" rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + waiverConfigurations.font_family_card">

    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <not-found-page v-show="notFound"></not-found-page>

<!--    <div class="row mt-2 mr-2 justify-content-end text-right ">-->
<!--      <div class="col-12">-->
<!--        <base-button :style="{'font-family': waiverConfigurations.font_family, 'color': waiverConfigurations.text_color}" v-if="!is_authenticated" link type="success" v-on:click="login()"><i class="tim-icons icon-single-02"></i> Login</base-button>-->
<!--        <base-button :style="{'font-family': waiverConfigurations.font_family, 'color': waiverConfigurations.text_color}" v-if="!is_authenticated" link type="success" v-on:click="register()"><i class="tim-icons icon-laptop"></i> Register</base-button>-->
<!--        <base-button :style="{'font-family': waiverConfigurations.font_family, 'color': waiverConfigurations.text_color}" v-if="is_authenticated" link type="success" v-on:click="toProfile()" class="mr-3"><i class="tim-icons icon-single-02"></i> Account</base-button>-->
<!--      </div>-->
<!--    </div>-->
    <div class="container" v-show="!notFound">
      <div class="row">
        <div class="col-md-6 mt-5 mb-4 ml-auto mr-auto text-center">
          <img :height="waiverConfigurations.logo_height" :width="waiverConfigurations.logo_width" :style="{'object-fit': waiverConfigurations.logo_fit}" :src="waiverConfigurations.logo" alt="logo">
          <h4 :style="{'font-family': waiverConfigurations.font_family, 'color': waiverConfigurations.text_color}" class="mt-3 description" v-if="currentStep === 0">
            {{waiverConfigurations.main_message}}
          </h4>
        </div>
      </div>
      <!--Step 1 - Choose your experience-->
      <div class="row justify-content-center text-center" v-if="currentStep === 0 && waiverConfigurations.display_section_titles">
        <div class="col">
          <h3 :style="{'font-family': waiverConfigurations.font_family}"> <span class="text-uppercase" :style="{'color': waiverConfigurations.text_color }">
           {{ sections[0].name }}
          </span></h3>
        </div>
      </div>
<!--      Compact-->
      <div v-if="waiverConfigurations.is_compact && currentStep === 0">
        <div class="row justify-content-center text-center" v-if="!is_kiosk">
          <div class="col">
            <div class="row mb-1 justify-content-center"
                 :style="{'font-family': waiverConfigurations.font_family}">
              <a :style="{'color': waiverConfigurations.text_color, 'font-size': 'medium' }"
                 v-if="showPrevious(current)" v-on:click="decrementDay(current)"
                 class="btn btn-link btn-primary d-none d-sm-block">
                <i class="tim-icons icon-minimal-left"></i> &nbsp;  {{ $t('checkin.previous') }}
              </a>
              <base-input :secondary-color="waiverConfigurations.text_color_card"
                          class="mt-2" inputmode='none'>
                <el-date-picker
                  prefix-icon="' '"
                  :size="'small'"
                  type="date"
                  :placeholder="$t('checkin.selectDate')"
                  value-format="yyyy-MM-dd"
                  :format="day_of_week + ' ' + locale.date_format"
                  v-model="current"
                  @change="changeDate()"
                  :picker-options="{ disabledDate: (time) => disabledDates(time, current) }"
                >
                </el-date-picker>
              </base-input>
              <a :style="{'color': waiverConfigurations.text_color, 'font-size': 'medium' }"
                 v-on:click="incrementDay(current)"
                 class="btn btn-link btn-primary d-none d-sm-block">
                {{ $t('checkin.next') }} &nbsp;<i class="tim-icons icon-minimal-right"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="row justify-content-center" v-if="waiverConfigurations.multiple_games">
          <div class="col-lg-6 text-center">
            <base-button :disabled="checkInGames.length === 0" simple v-on:click="selectMultipleCompact()"
                         :style="{'color': waiverConfigurations.text_color, 'font-size': 'medium',
                           'border-color': waiverConfigurations.text_color, 'width': '100%', 'font-size': 'large', 'font-weight': 'bold'}">
              <span v-if="checkInGames.length === 0"> {{ $t('checkin.selectGamesToCheckIn') }}  </span>
              <span v-if="checkInGames.length === 1"> {{ $t('checkin.checkInForOneGames') }} </span>
              <span v-if="checkInGames.length > 1"> {{ $t('checkin.checkInForXGames', { num: checkInGames.length}) }} </span>
              <i v-if="checkInGames.length > 0" :style="{'font-size': 'x-large', 'font-weight': 'bold'}" class="tim-icons el-icon-right ml-2"></i>
            </base-button>
          </div>
        </div>
        <div class="row mb-5 justify-content-center d-flex align-items-stretch" >
          <div class="col-lg-4 mt-3 col-md-4 col-sm-6 col-11" v-for="(game, index) in gameOptions" :key="index + 'G' + j">
            <card class="h-100" :style="{ 'background-color': waiverConfigurations.card_color}">
              <div slot="image" style="padding-bottom: 0px; height: 250px; margin-bottom: -50px">
                <el-carousel :arrow="getArrowSetting(JSON.parse(game.game_images))" :autoplay="false" indicator-position="none" :interval="5000">
                  <el-carousel-item style="border-radius: 5px; width: 100%; height: 200px;"
                                    :style="{'background-color': item.background_color}" v-for="(item, index) in JSON.parse(game.game_images)"
                                    :key="'CS' + index">
                    <div  style="border-radius: 5px; width: 100%; height: 200px; position: relative">
                      <img
                        :style="{'transform': 'translate(0%, 0%) scale(' + item.scale*0.01 + ')', 'left': item.x + 'px', 'top': item.y + 'px', 'object-fit':  item.object_fit, 'height': '100%'}"
                        class="card-img"
                        style="position: absolute; transform-origin: center center; will-change: transform"
                        slot="image" :src="item.url"  alt="Card image cap"/>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>

              <div class="row">
                <div class="col text-center">
                  <h4 :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}" >{{game.name}}</h4>
                </div>
              </div>

              <!--   No data panel -->
              <div style="height:75%" v-if="game.slots.length <= 0">
                <div class="empty-bookings h-100" :style="{ 'font-family': waiverConfigurations.font_family_card,
                                  'border-color': waiverConfigurations.button_color,
                                  'color': waiverConfigurations.button_color }">
                        <span style="font-size: 2.1em">
                          <i class="tim-icons icon-calendar-60"></i>
                        </span>
                  <p :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}" class="mt-3">
                    {{ $t('checkin.noBookingsAvailable') }}
                  </p>
                </div>
              </div>
              <div class="row" style="margin-bottom: -30px; padding-left: 6px; padding-right: 6px">
                <div style="display: inline-flex; padding-left: 4px; padding-right: 4px" class="col-6 col-sm-6 col-lg-4 col-md-6" v-for="(s, i) in game.slots" :key="'S' + i" :ref="'el'+i">
                  <base-button v-if="!s.selected" style="margin-right: 0px; margin-left: 0px;  overflow-wrap: anywhere" :style="{ 'font-family': waiverConfigurations.font_family_card,
                                  'border-color': waiverConfigurations.button_color,
                                  'color': waiverConfigurations.button_color, 'width': '100%', 'padding-left': '5px',  'padding-right': '5px' }"

                                v-on:click="selectBookingCompactView(s, game)"
                                simple >
                    {{getTime(locale.time_format, s.start_time)}}
                  </base-button>

                  <base-button v-if="s.selected" style="overflow-wrap: anywhere" :style="{ 'font-family': waiverConfigurations.font_family_card,
                                  'border-color': waiverConfigurations.button_color,
                                  'color': waiverConfigurations.button_color, 'width': '100%', 'padding-x': '5px', 'padding-left': '3px',  'padding-right': '3px' }"
                               v-on:click="unselectBookingCompactView(s)"
                               simple >
                    <i style="font-weight: bolder" class="tim-icons icon-check-2 mr-1"></i> {{getTime(locale.time_format, s.start_time)}}
                  </base-button>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>


<!--      Not compact-->
      <div class="row justify-content-center" v-if="!waiverConfigurations.is_compact && currentStep === 0">
        <div class="col-lg-4 col-md-4 col-sm-6 col-11" v-for="game in gameOptions" :key="game.id">
          <card :style="{ 'background-color': waiverConfigurations.card_color}">
            <div slot="image" class="card-img-top" style="padding-bottom: 0; height: 200px">
              <el-carousel :arrow="getArrowSetting(JSON.parse(game.game_images))" :autoplay="false" indicator-position="none" :interval="5000">
                <el-carousel-item style="border-radius: 5px; width: 100%; height: 200px;"
                                  :style="{'background-color': item.background_color}" v-for="(item, index) in JSON.parse(game.game_images)"
                                  :key="'CS' + index">
                  <div  style="border-radius: 5px; width: 100%; height: 200px; position: relative">
                    <img
                      :style="{'transform': 'translate(0%, 0%) scale(' + item.scale*0.01 + ')', 'left': item.x + 'px', 'top': item.y + 'px', 'object-fit':  item.object_fit, 'height': '100%'}"
                      class="card-img"
                      style="position: absolute; transform-origin: center center; will-change: transform"
                      slot="image" :src="item.url"  alt="Card image cap"/>
<!--                    <img-->
<!--                         :style="{'transform': 'translate(0%, 0%) scale(' + item.scale*0.01 + ')', 'left': item.x + 'px', 'top': item.y + 'px', 'object-fit': 'cover', 'height': '100%', 'width': '100%'}"-->
<!--                         style="position: absolute; object-fit: cover; transform-origin: center center; will-change: transform;" :src="item.url">-->
                  </div>
                </el-carousel-item>
              </el-carousel>
            </div>

<!--            <img slot="image" class="card-img-top fixed-size-img" :src="game.img"  alt="Card image cap"/>-->
            <!--<h4 class="card-title">{{game.name}}</h4>-->
            <div class="row justify-content-center">
              <div class="col-lg-12 ">
                <base-button simple size="lg" v-on:click="selectGame(game)"
                             class="game-button"
                             :style="{ 'font-family': waiverConfigurations.font_family_card, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }">
                  {{game.name}}</base-button>
              </div>
            </div>
          </card>
        </div>
      </div>
<!--      Step 2 - Pick from available booking slots-->
      <div class="row justify-content-center text-center" v-if="currentStep === 1 && display.includes('Booking Date') && waiverConfigurations.display_section_titles">
        <div class="col">
          <h3 :style="{'font-family': waiverConfigurations.font_family}"> <span class="text-uppercase" :style="{'color': waiverConfigurations.text_color }">
            <span class="mt-4">{{ sections[1].name }}</span>
          </span></h3>
        </div>

      </div>
      <div class="row" v-if="currentStep === 1 && display.includes('Booking Date')">
        <div class="col-lg-1 ml-4 col-2">
          <base-button
            :style="{ 'font-family': waiverConfigurations.font_family,
                        'border-color': waiverConfigurations.text_color,
                        'color': waiverConfigurations.text_color,
                        'width': '100%',
                        'font-size': 'large', 'font-weight': 'bold'}"
            v-on:click="backButton()" link >
            <i :style="{'font-size': 'x-large', 'font-weight': 'bold'}" class="tim-icons el-icon-back"></i>
          </base-button>
        </div>
      </div>
      <div class="row justify-content-center text-center" v-if="currentStep === 1 && display.includes('Booking Date')">
        <div class="col">
          <div class="row mb-1 justify-content-center" :style="{'font-family': waiverConfigurations.font_family}" v-if="!is_kiosk">
              <a :style="{'color': waiverConfigurations.text_color, 'font-size': 'medium' }" v-if="showPrevious(current)" v-on:click="decrementDay(current)"  class="btn btn-link btn-primary d-none d-sm-block">
                <i class="tim-icons icon-minimal-left"></i> &nbsp; {{ $t('checkin.previous') }}
              </a>
              <base-input :secondary-color="waiverConfigurations.text_color_card"
                          class="mt-2" inputmode='none'>
                <el-date-picker
                  prefix-icon="' '"
                  :size="'small'"
                  type="date"
                  :placeholder="$t('checkin.selectDate')"
                  value-format="yyyy-MM-dd"
                  :format="day_of_week + ' ' + locale.date_format"
                  v-model="current"
                  @change="changeDate()"
                  :picker-options="{ disabledDate: (time) => disabledDates(time, current) }"
                >
                </el-date-picker>
              </base-input>
              <a :style="{'color': waiverConfigurations.text_color, 'font-size': 'medium' }"
                 v-on:click="incrementDay(current)"
                 class="btn btn-link btn-primary d-none d-sm-block">
                {{ $t('checkin.next') }} &nbsp;<i class="tim-icons icon-minimal-right"></i>
              </a>
          </div>
        </div>
      </div>
      <div class="row justify-content-center" v-if="currentStep === 1 && display.includes('Booking Date')">
        <div class="col-10" v-if="noBookingsAvailable === true">
          <div class="font-icon-detail" :style="{'border': '1px solid ' + waiverConfigurations.text_color + ' !important'}">
              <span style="font-size: 3em">
                <i :style="{'color': waiverConfigurations.text_color}" class="fas fa-exclamation-triangle"></i>
              </span>
            <h4 :style="{'font-family': waiverConfigurations.font_family, 'color': waiverConfigurations.text_color}" class="mt-3"> {{ $t('checkin.noAvailableBookings') }}</h4>
          </div>
        </div>
        <div class="col-lg-4 col-md-7 col-sm-7 col-10" v-for="booking in bookings" :key="booking.id">
          <card :style="{ 'background-color': waiverConfigurations.card_color}">

            <div class="row justify-content-center">
              <div class="col-lg-12 ">
                <base-button simple size="lg" v-on:click="selectBookingDate(booking)"
                             class="game-button"
                             :style="{ 'font-family': waiverConfigurations.font_family_card, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }">
                  <span class="ml-1 mt-1">{{getTime(locale.time_format, booking.start_time)}}&nbsp;</span>
                  </base-button>
              </div>
            </div>
          </card>
        </div>
      </div>

      <!--      Step 3 - Select additional games-->
      <div class="row justify-content-center" v-if="currentStep === 2">
        <div class="col-lg-6 col-md-7 col-sm-7 col-12" :style="{'font-family': waiverConfigurations.font_family_card}">
          <card :style="{ 'background-color': waiverConfigurations.card_color}">
            <div class="row justify-content-center">
              <div class="col-lg-12 text-center ">
                <p class="mb-3" :style="{ 'color': waiverConfigurations.text_color_card}">{{ $t('checkin.checkInForMultipleGames') }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-1 col-2">
                <base-button
                  :style="{ 'font-family': waiverConfigurations.font_family_card,
                        'border-color': waiverConfigurations.button_color,
                        'color': waiverConfigurations.button_color,
                        'width': '100%',
                        'font-size': 'large', 'font-weight': 'bold'}"
                  v-on:click="backButton()" link >
                  <i :style="{'font-size': 'x-large', 'font-weight': 'bold'}" class="tim-icons el-icon-back"></i>
                </base-button>
              </div>
              <div class="col">
                <base-button simple v-on:click="selectAdditionalGames()"
                             :style="{'font-family': waiverConfigurations.font_family_card, 'width': '100%',
                              'border-color': waiverConfigurations.button_color,
                        'color': waiverConfigurations.button_color }">
                  {{ $t('checkin.yes') }}
                </base-button>
              </div>
              <div class="col">
                <base-button simple v-on:click="noAdditionalGames()"
                             :style="{'font-family': waiverConfigurations.font_family_card, 'width': '100%',
                               'border-color': waiverConfigurations.button_color,
                        'color': waiverConfigurations.button_color }">
                  {{ $t('checkin.no') }}
                </base-button>
              </div>
            </div>
          </card>
        </div>
      </div>


  <!--      Step 4:  Player info-->
      <div class="row justify-content-center text-center" v-if="currentStep === 3 && waiverConfigurations.display_section_titles &&
       (display.includes('First Name') || display.includes('Last Name') || display.includes('Email')
       || display.includes('Phone'))">
        <div class="col">
          <h3 :style="{'font-family': waiverConfigurations.font_family}"> <span class="text-uppercase" :style="{'color': waiverConfigurations.text_color }">
           <span class="mt-4">{{ sections[2].name }}</span>
          </span></h3>
        </div>
      </div>
      <div :style="{'font-family': waiverConfigurations.font_family_card}" class="row justify-content-center" v-if="currentStep === 3 &&
       (display.includes('First Name') || display.includes('Last Name') || display.includes('Email')
       || display.includes('Phone'))">
        <div class="col-lg-6">
          <card :style="{ 'background-color': waiverConfigurations.card_color}">
            <div class="row mt-5 justify-content-center">
              <div class="col" v-if="display.includes('First Name')">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{ $t('checkin.firstName') }}
                  <span v-if="requiredFields.includes('First Name')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :error="errs.first_name" :secondary-color="waiverConfigurations.text_color_card" :input-style="{'color': waiverConfigurations.text_color_card}" :placeholder="$t('checkin.firstName')"
                            v-model="user.first_name"></base-input>
              </div>
              <div class="col" v-if="display.includes('Last Name')">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{ $t('checkin.lastName') }}
                  <span v-if="requiredFields.includes('Last Name')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :error="errs.last_name" :secondary-color="waiverConfigurations.text_color_card" :input-style="{'color': waiverConfigurations.text_color_card}" :placeholder="$t('checkin.lastName')"
                            v-model="user.last_name"></base-input>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col" v-if="display.includes('Birthday')">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{ $t('checkin.birthday') }}
                  <span v-if="requiredFields.includes('Birthday')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :error="errs.birthday" :secondary-color="waiverConfigurations.text_color_card" :input-style="{'color': waiverConfigurations.text_color_card}"
                            v-mask="'##/##/####'" placeholder="mm/dd/yyyy"
                            v-model="user.birthday"></base-input>
              </div>
              <div class="col" v-if="display.includes('Phone')">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{ $t('checkin.phone') }}
                  <span v-if="requiredFields.includes('Phone')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>


<!--                {{requiredFields.includes('Phone')}}-->
<!--                <base-input :secondary-color="waiverConfigurations.text_color_card" :style="{'color': waiverConfigurations.text_color_card}">-->
<!--                  <VuePhoneNumberInput ref="tel" :required="requiredFields.includes('Phone')"-->
<!--                   :preferred-countries="['US', 'GB']" @update="onCountryChanged" :dark-color="waiverConfigurations.card_color"-->
<!--                    :color="waiverConfigurations.text_color_card"  valid-color="yellowgreen"-->
<!--                                       dark v-model="phone" />-->
<!--                </base-input>-->
                <base-input :error="errs.phone" :secondary-color="waiverConfigurations.text_color_card" :input-style="{'color': waiverConfigurations.text_color_card}"
                            :placeholder="phone_placeholder"
                            v-model="user.phone"></base-input>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col" v-if="display.includes('Email')">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{ $t('checkin.email') }}
                  <span v-if="requiredFields.includes('Email')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :error="errs.email" :secondary-color="waiverConfigurations.text_color_card" :input-style="{'color': waiverConfigurations.text_color_card}" v-model="user.email" :placeholder="$t('checkin.email')"></base-input>
              </div>
            </div>
            <div class="row mb-2 justify-content-center">
              <div class="col-lg-1 col-2">
                <base-button
                  :style="{ 'font-family': waiverConfigurations.font_family_card,
                        'border-color': waiverConfigurations.button_color,
                        'color': waiverConfigurations.button_color,
                        'width': '100%',
                        'font-size': 'large', 'font-weight': 'bold'}"
                  v-on:click="backButton()" link >
                  <i :style="{'font-size': 'x-large', 'font-weight': 'bold'}" class="tim-icons el-icon-back"></i>
                </base-button>
              </div>
              <div class="col">
                <base-button :style="{'font-family': waiverConfigurations.font_family_card, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }"
                  simple class="game-button" v-on:click="setUserData()">{{ $t('checkin.next') }}</base-button>
              </div>
            </div>

          </card>
        </div>
      </div>

      <!--      Step 5 - Minors-->
      <div class="row justify-content-center text-center" v-if="currentStep === 4 && waiverConfigurations.display_section_titles">
        <div class="col">
          <h3 :style="{'font-family': waiverConfigurations.font_family}" > <span class="text-uppercase" :style="{'color': waiverConfigurations.text_color }">
             <span class="mt-4">{{ sections[3].name }}</span>
          </span></h3>
        </div>
      </div>
      <div class="row justify-content-center" v-if="currentStep === 4 &&  has_minors == false">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12">
          <card :style="{ 'background-color': waiverConfigurations.card_color}">
            <div class="row justify-content-center">
              <div :style="{'font-family': waiverConfigurations.font_family_card}" class="col-lg-12 col-12 text-center ">
                <p class="mb-3" :style="{'color': waiverConfigurations.text_color_card}">{{ $t('checkin.areYouAccompanyingChildren') }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-1 col-2">
                <base-button
                  :style="{ 'font-family': waiverConfigurations.font_family_card,
                        'border-color': waiverConfigurations.button_color,
                        'color': waiverConfigurations.button_color,
                        'width': '100%',
                        'font-size': 'large', 'font-weight': 'bold'}"
                  v-on:click="backButton()" link >
                  <i :style="{'font-size': 'x-large', 'font-weight': 'bold'}" class="tim-icons el-icon-back"></i>
                </base-button>
              </div>
              <div class="col">
                <base-button :style="{'width': '100%', 'font-family': waiverConfigurations.font_family_card, 'border-color': waiverConfigurations.button_color,
                        'color': waiverConfigurations.button_color}" simple  v-on:click="isMinor(1)">
                  {{ $t('checkin.yes') }}
                </base-button>

              </div>
              <div class="col">
                <base-button :style="{'width': '100%', 'font-family': waiverConfigurations.font_family_card, 'border-color': waiverConfigurations.button_color,
                        'color': waiverConfigurations.button_color}" simple  v-on:click="isMinor(0)">
                  {{ $t('checkin.no') }}
                </base-button>
              </div>
            </div>
          </card>
        </div>
      </div>
      <div class="row justify-content-center" v-if="currentStep === 4 && has_minors == true">
        <div class="col-lg-8" :style="{'font-family': waiverConfigurations.font_family_card}">
          <card :style="{ 'background-color': waiverConfigurations.card_color}" >
            <div class="row justify-content-between" v-for="(m, i) in minors" :key="'A' + i + x">
              <div class="col-10 col-lg-4" v-if="display.includes('Child First Name')" style="padding-right: 0px;">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{ $t('checkin.firstName') }}
                  <span :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}" v-if="requiredFields.includes('Child First Name')">*</span>
                </label>
                <base-input :error="m.error_first_name"
                            :secondary-color="waiverConfigurations.text_color_card"
                            :input-style="{'color': waiverConfigurations.text_color_card}"  :placeholder="$t('checkin.firstName')"
                            v-model="m.first_name"></base-input>
              </div>
              <div class="col-10 col-lg-4" v-if="display.includes('Child Last Name')" style="padding-right: 0px;">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{ $t('checkin.lastName') }}
                  <span :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}" v-if="requiredFields.includes('Child Last Name')">*</span>
                </label>
                <base-input :error="m.error_last_name" :secondary-color="waiverConfigurations.text_color_card" :input-style="{'color': waiverConfigurations.text_color_card}" :placeholder="$t('checkin.lastName')"
                            v-model="m.last_name"></base-input>
              </div>
              <div class="col-10 col-lg-3" v-if="display.includes('Child Birthday')" style="padding-right: 0px;">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{ $t('checkin.birthday') }}
                  <span :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}" v-if="requiredFields.includes('Child Birthday')">*</span>
                </label>
                <base-input :error="m.error_birthday" :secondary-color="waiverConfigurations.text_color_card"
                            :input-style="{'color': waiverConfigurations.text_color_card}"
                            v-mask="'##/##/####'" placeholder="mm/dd/yyyy"
                            v-model="m.birthday"></base-input>
              </div>
              <div class="col-1 mr-4 mr-lg-0" >
                <i :style="{ 'color': waiverConfigurations.button_color }" v-on:click="removeChild(i)"
                   style="cursor: pointer; margin-top: 37px; padding-left: 0px"
                   class="tim-icons icon-simple-remove"></i>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col">
                <base-button :style="{ 'font-family': waiverConfigurations.font_family_card, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }" simple v-on:click="addMinor()">
                  {{ $t('checkin.addAnotherChild') }}
                </base-button>
              </div>
            </div>
            <div class="row mb-2" v-if="waiverConfigurations.show_participation_field">
              <div class="col">
                <base-checkbox :border-color="waiverConfigurations.button_color" :accent-color="waiverConfigurations.button_color"
                                :check-mark-color="waiverConfigurations.card_color"
                               v-model="not_participating">
                  <span :style="{'color': waiverConfigurations.text_color_card}">{{ waiverConfigurations.participation_field_text }}</span>
                </base-checkbox>
              </div>
            </div>
            <div class="row mb-2 justify-content-center">
              <div class="col-lg-1 col-2">
                <base-button
                  :style="{ 'font-family': waiverConfigurations.font_family_card,
                        'border-color': waiverConfigurations.button_color,
                        'color': waiverConfigurations.button_color,
                        'width': '100%',
                        'font-size': 'large', 'font-weight': 'bold'}"
                  v-on:click="backButton()" link >
                  <i :style="{'font-size': 'x-large', 'font-weight': 'bold'}" class="tim-icons el-icon-back"></i>
                </base-button>
              </div>
              <div class="col">
                <base-button :style="{ 'font-family': waiverConfigurations.font_family_card, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }"
                             class="game-button" simple type="success" v-on:click="setMinorData()">{{ $t('checkin.next') }}</base-button>
              </div>
            </div>
          </card>
        </div>
      </div>


      <!--      Step 6 - Signature and waiver text-->
      <div class="row justify-content-center text-center" v-if="currentStep === 5 && waiverConfigurations.display_section_titles">
        <div class="col" :style="{'font-family': waiverConfigurations.font_family}">
          <h3> <span class="text-uppercase" :style="{'color': waiverConfigurations.text_color }">
            <span class="mt-4">{{ sections[4].name }}</span>
          </span></h3>
        </div>
      </div>
      <div class="row justify-content-center" v-if="currentStep === 5">
        <div class="col-lg-8 col-md-10 col-sm-12 col-12" :style="{'font-family': waiverConfigurations.font_family_card}">
          <card :style="{ 'background-color': waiverConfigurations.card_color}"
                footer-classes="text-center mb-3 mt-3"
          >
            <span v-html="waiverConfigurations.waiver_text"></span>
<!--            <textarea :style="{'background-color': 'white'}" v-if="waiverConfigurations.waiver_text" disabled rows="30" v-model="waiverConfigurations.waiver_text"></textarea>-->
            <div class="mt-2" v-if="waiverConfigurations.external_link">
              <base-checkbox :border-color="waiverConfigurations.button_color" :accent-color="waiverConfigurations.button_color"
                             :check-mark-color="waiverConfigurations.card_color"
                             v-model="conditions">
               <span :style="{'color': waiverConfigurations.text_color_card}">{{ $t('checkin.agreeToToS') }}
                 <a :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.link_color}" target="_blank" :href="waiverConfigurations.external_link">{{ $t('checkin.termsAndConditions') }}</a>.</span>

              </base-checkbox>
              <p :style="{'font-family': waiverConfigurations.font_family_card, 'color': '#ec250d'}" class="small ml-4" v-if="errs.conditions">
                {{ errs.conditions }}</p>
            </div>
            <div v-if="display.includes('Signature')" class="row mt-3 justify-content-between">
              <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                <h6 :style="{'color': waiverConfigurations.text_color_card}" class="mt-3">{{ $t('checkin.signature') }}</h6>
              </div>
              <div class="mr-3 ml-3">
                <base-button :style="{'font-family': waiverConfigurations.font_family_card, 'border-color': waiverConfigurations.button_color,'color': waiverConfigurations.button_color}" size="sm" align="left" simple v-on:click="clearSignature()" type="secondary">
                  {{ $t('checkin.clearSignature') }}
                </base-button>
              </div>
            </div>

            <div class="row" v-if="display.includes('Signature')">

              <div class=" col-lg-12">
                <VueSignaturePad :custom-style="background_sig" :style="{'border-color': waiverConfigurations.button_color}" class="sig-pad" height="200px" ref="signaturePad" />
                <p :style="{'font-family': waiverConfigurations.font_family_card, 'color': '#ec250d'}" class="small" v-if="errs.signature">
                  {{ errs.signature }}</p>
              </div>
            </div>
            <div v-if="display.includes('Photo Opt Out')" class="row">
              <div class="col">
                <base-checkbox  :border-color="waiverConfigurations.button_color" :accent-color="waiverConfigurations.button_color"
                                :check-mark-color="waiverConfigurations.card_color" class="ml-1" v-model="picture_optout">
                  <span :style="{'color': waiverConfigurations.text_color_card}">{{ $t('checkin.doNotSharePhotos') }}</span>
                </base-checkbox>
              </div>
            </div>
            <div v-if="display.includes('Email Opt Out')" class="row">
              <div class="col-12 col-lg-12">
                <base-checkbox  :border-color="waiverConfigurations.button_color" :accent-color="waiverConfigurations.button_color"
                                :check-mark-color="waiverConfigurations.card_color" class="ml-1" v-model="email_optout">
                  <span :style="{'color': waiverConfigurations.text_color_card}">{{ $t('checkin.doNotIncludeInMailingLists') }}</span>
                </base-checkbox>
              </div>
            </div>
            <div class="row mt-3 mb-2 justify-content-end">
              <div class="col-lg-1 col-2">
                <base-button
                  :style="{ 'font-family': waiverConfigurations.font_family_card,
                        'border-color': waiverConfigurations.button_color,
                        'color': waiverConfigurations.button_color,
                        'width': '100%',
                        'font-size': 'large', 'font-weight': 'bold'}"
                  v-on:click="backButton()" link >
                  <i :style="{'font-size': 'x-large', 'font-weight': 'bold'}" class="tim-icons el-icon-back"></i>
                </base-button>
              </div>
              <div class="col">
                <base-button :style="{ 'font-family': waiverConfigurations.font_family_card, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }"
                         simple class="game-button" v-on:click="setSignatureData()">{{ $t('checkin.next') }}</base-button>
              </div>
            </div>
          </card>
        </div>
      </div>

      <!--      Step 7 - Marketing-->
      <div class="row justify-content-center text-center" v-if="currentStep === 6 && waiverConfigurations.display_section_titles">
        <div class="col">
          <h3 :style="{'font-family': waiverConfigurations.font_family}"> <span class="text-uppercase" :style="{'color': waiverConfigurations.text_color }">
            <span class="mt-4">{{ sections[5].name }}</span>
          </span></h3>
        </div>
      </div>
      <div class="row justify-content-center" v-if="currentStep === 6">
        <div class="col-lg-6 col-md-7 col-sm-7 col-12" :style="{'font-family': waiverConfigurations.font_family_card}">
          <card :style="{ 'background-color': waiverConfigurations.card_color}">
            <div v-if="display.includes('Source')" class="row">
              <div class="col">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{ $t('checkin.howDidYouHearAboutUs') }}
                  <span v-if="requiredFields.includes('Source')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :error="errs.source" :secondary-color="waiverConfigurations.text_color_card" :placeholder="$t('checkin.select')">
                  <base-dropdown :style="{'font-family': waiverConfigurations.font_family_card }"
                                 title-classes="form-group form-control" :text-color="waiverConfigurations.button_color"
                                 :font-family="waiverConfigurations.font_family_card"
                                 :title="selectedSource.label"
                  >
                    <a v-for="(s, i) in sources"  :style="{'font-family': waiverConfigurations.font_family_card }"
                       style="white-space: normal; overflow-wrap: break-word;"
                       class="dropdown-item" href="#" :key="'DOJ' + i" v-on:click="selectedSource = s">
                      {{ s.label }}</a>
                  </base-dropdown>
                </base-input>
              </div>
              <div v-if="selectedSource.label === 'Other'" class="col">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{ $t('checkin.pleaseSpecify') }}
                </label>
                <base-input :secondary-color="waiverConfigurations.text_color_card"
                            v-model="otherSource" :input-style="{'color': waiverConfigurations.text_color_card}" :placeholder="$t('checkin.source')"
                ></base-input>
              </div>
            </div>

            <div v-if="display.includes('Experience')" class="row">
              <div class="col">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{ $t('checkin.experience') }}
                  <span v-if="requiredFields.includes('Experience')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :error="errs.experience" :secondary-color="waiverConfigurations.text_color_card" :placeholder="$t('checkin.select')">
                  <base-dropdown :style="{'font-family': waiverConfigurations.font_family_card }"
                                 title-classes="form-group form-control" :text-color="waiverConfigurations.button_color"
                                 :font-family="waiverConfigurations.font_family_card"
                                 :title="selectedExperience.label"
                  >
                    <a v-for="(e, i) in experiences"  :style="{'font-family': waiverConfigurations.font_family_card }"
                       style="white-space: normal; overflow-wrap: break-word;"
                       class="dropdown-item" href="#" :key="'EXX' + i" v-on:click="selectedExperience = e">
                      {{ e.label }}</a>
                  </base-dropdown>
                </base-input>
              </div>
            </div>

            <div v-if="display.includes('Location')" class="row">
              <div class="col-lg-6">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{ $t('checkin.whereAreYouFrom') }}
                  <span v-if="requiredFields.includes('Location')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :error="errs.location.city" :secondary-color="waiverConfigurations.text_color_card"
                            style="margin-bottom: 0px;" :placeholder="$t('checkin.city')" v-model="city" :input-style="{'color': waiverConfigurations.text_color_card}">
                </base-input>
              </div>
              <div class="col-lg-6">
                <label :style="{'color': waiverConfigurations.text_color_card}"> &nbsp;
                </label>
                <base-input :error="errs.location.state" :secondary-color="waiverConfigurations.text_color_card"
                            style="margin-bottom: 0px;" :placeholder="state_placeholder" v-model="selectedState" :input-style="{'color': waiverConfigurations.text_color_card}">
                </base-input>
              </div>
            </div>

            <div class="row mt-3 justify-content-center">
              <div class="col-lg-1 col-2">
                <base-button
                  :style="{ 'font-family': waiverConfigurations.font_family_card,
                        'border-color': waiverConfigurations.button_color,
                        'color': waiverConfigurations.button_color,
                        'width': '100%',
                        'font-size': 'large', 'font-weight': 'bold'}"
                  v-on:click="backButton()" link >
                  <i :style="{'font-size': 'x-large', 'font-weight': 'bold'}" class="tim-icons el-icon-back"></i>
                </base-button>
              </div>
              <div class="col text-center">
                <base-button simple  v-on:click="setMarketingData()"
                             class="game-button"
                             :style="{'font-family': waiverConfigurations.font_family_card, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }">
                  <span class="ml-1 mt-1">{{ $t('checkin.next') }}</span>
                </base-button>
              </div>
            </div>
          </card>
        </div>
      </div>


    <!--      Step 7 - Confirm-->
      <div class="row justify-content-center text-center" v-if="currentStep === 7 && waiverConfigurations.display_section_titles">
        <div class="col">
          <h3 :style="{'font-family': waiverConfigurations.font_family}"> <span class="text-uppercase" :style="{'color': waiverConfigurations.text_color }">
            <span class="mt-4">{{sections[6].name}}</span>
          </span></h3>
        </div>
      </div>
      <div class="row justify-content-center" v-if="currentStep === 7">
        <div class="col-lg-6 col-md-7 col-sm-7 col-12" :style="{'font-family': waiverConfigurations.font_family_card}">
          <card :style="{ 'background-color': waiverConfigurations.card_color}">
            <div class="row justify-content-center">
              <div class="col-lg-12 text-center">
                <p :style="{'color': waiverConfigurations.text_color_card}" class="mb-3">{{ $t('checkin.confirmText') }}</p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-12 text-center " v-for="(c, i) in checkInGames" :key="'C' + i">
                  <p :style="{'color': waiverConfigurations.text_color_card}" class="mb-3">{{c.game.name}}:
                    {{getDayOfWeek( c.booking.booking_date)}} {{getFormattedDate(locale.date_format, c.booking.booking_date)}},
                    {{getTime(locale.time_format, c.booking.start_time)}}
                  </p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-lg-1 col-2">
                <base-button
                  :style="{ 'font-family': waiverConfigurations.font_family_card,
                        'border-color': waiverConfigurations.button_color,
                        'color': waiverConfigurations.button_color,
                        'width': '100%',
                        'font-size': 'large', 'font-weight': 'bold'}"
                  v-on:click="backButton()" link >
                  <i :style="{'font-size': 'x-large', 'font-weight': 'bold'}" class="tim-icons el-icon-back"></i>
                </base-button>
              </div>
              <div class="col text-center">
                <base-button simple  v-on:click="confirmCheckin()" :disabled="disabled"
                             class="game-button"
                             :style="{'font-family': waiverConfigurations.font_family_card, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }">
                  <span class="ml-1 mt-1">{{ $t('checkin.confirm') }}</span>
                </base-button>
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {API_LOCATION, WAIVER_URL} from 'src/api-config'
import { BaseAlert } from 'src/components';
import {Select, Option, DatePicker, TimePicker, TimeSelect, Carousel, CarouselItem} from 'element-ui'
import NotFoundPage from "../../pages/GeneralViews/NotFoundPage";
const axios = require('axios')
import {date, time, formattedDate, dayOfWeek} from '/src/plugins/dateFormatter'
import {Auth} from "aws-amplify";
import swal from "sweetalert2";
import _ from 'lodash'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

const disabledDate = (time) => {
  return time.getTime() > Date.now()
}

export default {
  name: "Waiver",
  components: {
    NotFoundPage,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [TimePicker.name]: TimePicker,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Select.name]: Select,
    [Option.name]: Option,
    BaseAlert,
    VuePhoneNumberInput
  },
  data() {
    return {
      is_kiosk: false,
      not_participating: false,
      x: 0,
      j: 0,
      time_slot_height: '50px',
      state_placeholder: 'State',
      phone_placeholder: '(999) 999-9999',
      phone: '',
      background_sig: { 'background-color': 'white'},
      loading: false,
      locale: '',
      has_minors: false,
      bookings: '',
      day_of_week: '',
      noBookingsAvailable: false,
      picture_optout: false,
      email_optout: false,
      selectedBooking: '',
      selectedGame: '',
      checkInGames: [],
      notFound: '',
      currentStep: 0,
      gameOptions: '',
      is_authenticated: false,
      waiverConfigurations: '',
      conditions: false,
      current: '',
      fields: [],
      sections: [],
      display: [],
      signature: '',
      selectedExperience: '',
      selectedSource: '',
      otherSource: '',
      selectedState: '',
      city: '',
      disabled: false,
      experiences: [
        {
          value: this.$t('checkin.exp.beginner'),
          label: this.$t('checkin.exp.beginnerDesc'),
        },
        {
          value: this.$t('checkin.exp.intermediate'),
          label: this.$t('checkin.exp.intermediateDesc'),
        },
        {
          value: this.$t('checkin.exp.expert'),
          label: this.$t('checkin.exp.expertDesc'),
        },
        {
          value: this.$t('checkin.exp.master'),
          label: this.$t('checkin.exp.masterDesc'),
        },
        {
          value: this.$t('checkin.exp.grandmaster'),
          label: this.$t('checkin.exp.grandmasterDesc'),
        }
      ],
      sources: [
        {
          value: 'Google',
          label: 'Google',
        },
        {
          value: 'Instagram',
          label: 'Instagram',
        },
        {
          value: 'Facebook',
          label: 'Facebook',
        },
        {
          value: 'Yelp',
          label: 'Yelp',
        },
        {
          value: this.$t('checkin.sources.wordOfMouth'),
          label: this.$t('checkin.sources.wordOfMouth'),
        },
        {
          value: this.$t('checkin.sources.other'),
          label: this.$t('checkin.sources.other'),
        },
      ],
      states: ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming'],
      user: {
        email: '',
        phone: '',
        first_name: '',
        last_name: '',
        birthday: ''
      },
      minors: [{
        first_name: '',
        last_name: '',
        birthday: ''
      }],
      errs: {
        email: '',
        first_name: '',
        last_name: '',
        birthday: '',
        phone: '',
        signature: '',
        conditions: '',
        source: '',
        location: {
          city: '',
          state: ''
        },
        experience: ''
      },
      requiredFields: [],
      max_time_slots: 0,
    }
  },
  computed: {
    today(){
      return new Date()
    }
  },
  methods: {
    isValidDate(dateString) {
      let regEx = /^\d{2}\/\d{2}\/\d{4}$/;
      // Check for the pattern
      if (!dateString.match(regEx)) return false;  // Invalid format
      let parts = dateString.split('/');
      let month = parseInt(parts[0], 10) - 1; // JavaScript months are 0-based
      let day = parseInt(parts[1], 10);
      let year = parseInt(parts[2], 10);
      let dateObj = new Date(year, month, day);
      // Check if the date object converted back matches the original parts to validate
      return dateObj.getFullYear() === year && dateObj.getMonth() === month && dateObj.getDate() === day;
    },
    isReasonableAge(birthday) {
      let parts = birthday.split('/');
      let birthYear = parseInt(parts[2], 10);
      let birthMonth = parseInt(parts[0], 10) - 1; // JavaScript months are 0-based
      let birthDay = parseInt(parts[1], 10);
      let birthDate = new Date(birthYear, birthMonth, birthDay);

      let today = new Date();
      let age = today.getFullYear() - birthYear;
      let m = today.getMonth() - birthMonth;

      if (m < 0 || (m === 0 && today.getDate() < birthDay)) {
        age--;
      }

      return age >= 0 && age <= 120;
    },
    isUnderEighteen(birthday) {
      let today = new Date();
      let birthDate = new Date(birthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      let monthDifference = today.getMonth() - birthDate.getMonth();

      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age < 18;
    },
    removeChild(index){
      this.minors.splice(index, 1);
    },
    getArrowSetting(images){
      if(!images){
        return ''
      }
      if(images.length <= 1){
        return 'never'
      }
      return "hover"
    },
    backButton(){
      if(this.currentStep === 7 &&
        (this.display.includes('Experience') || this.display.includes('Location') || this.display.includes('Source'))){
        this.currentStep = 6
        return
      } else if (this.currentStep === 7) {
        this.currentStep = 6
      }

      if(this.currentStep === 6 &&
        (this.display.includes('Signature') ||
          this.waiverConfigurations.waiver_text != null ||
          this.waiverConfigurations.external_link != null ||
          this.waiverConfigurations.includes('Photo Opt Out')
          || this.waiverConfigurations.includes('Email Opt Out'))){
            this.currentStep = 5
            return
      } else if (this.currentStep === 6) {
        this.currentStep = 5
      }

      if(this.currentStep === 5  &&
        (this.display.includes('Child First Name') || this.display.includes('Child Last Name') || this.display.includes('Child Birthday'))){
        this.currentStep = 4
        this.has_minors = false
        return
      } else if (this.currentStep === 5){
        this.currentStep = 4
      }

      if(this.currentStep === 4  &&
        this.display.includes('Email')){
        this.has_minors = false
        this.currentStep = 3

        return
      } else if (this.currentStep === 4){
        this.currentStep = 3
      }

      if(this.currentStep === 3 && this.waiverConfigurations.multiple_games == true){
        if(this.waiverConfigurations.is_compact){
          this.currentStep = 0
        } else {
          this.currentStep = 2
        }
        return
      } else if (this.currentStep === 3){
        if(this.waiverConfigurations.is_compact){
          this.currentStep = 0
        } else {
          this.currentStep = 2
        }
      }

      if(this.currentStep === 2 && (this.display.includes('Booking Time') && this.display.includes('Booking Date'))){
        this.currentStep = 1
        return
      } else if (this.currentStep === 2){
        this.currentStep = 1
      }

      if(this.currentStep === 1){
        this.currentStep = 0
        return
      }

      if (window !== window.top) {
        this.$nextTick(() => {
          this.updateParentAboutHeightChange()
        });
      };

      // this.currentStep = parseInt(this.currentStep) - 1
    },
    onCountryChanged(country) {
      //var paragraph = document.getElementById("MazPhoneNumberInput-258_phone_number");
      // let countrySelect = document.querySelectorAll('[id*="country_selector"]')[0];
      // countrySelect.setAttribute("style", "border-top-right-radius: 4px;\n" +
      //   "    border-bottom-right-radius: 4px;\n" +
      //   "    caret-color: rgb(224, 18, 18);\n" +
      //   "    border-color: rgb(224, 18, 18);\n" +
      //   "    border-top-color: rgb(224, 18, 18);\n" +
      //   "    border-right-color: rgb(224, 18, 18);\n" +
      //   "    border-bottom-color: rgb(224, 18, 18);\n" +
      //   "    border-left-color: rgb(224, 18, 18);\n" +
      //   "    background-color: rgb(247, 239, 244);\n" +
      //   "    color: lime;")
      //
      // let paragraph = document.querySelectorAll('[id*="phone_number"]')[0];
      // paragraph.setAttribute("style", "border-top-right-radius: 4px;\n" +
      // "    border-bottom-right-radius: 4px;\n" +
      // "    caret-color: rgb(224, 18, 18);\n" +
      // "    border-color: rgb(224, 18, 18);\n" +
      // "    border-top-color: rgb(224, 18, 18);\n" +
      // "    border-right-color: rgb(224, 18, 18);\n" +
      // "    border-bottom-color: rgb(224, 18, 18);\n" +
      // "    border-left-color: rgb(224, 18, 18);\n" +
      // "    background-color: rgb(247, 239, 244);\n" +
      // "    color: lime;")
      //   "-webkit-transition-duration:.3s;transition-duration:.3s;position:relative;width:100%;padding:0 12px;font-weight:400;-webkit-appearance:none;-moz-appearance:none;appearance:none;outline:none;border:1px solid #ccc;font-size:14px;z-index:0;margin-left:-1px;height:40px;min-height:40px}.input-tel__input[data-v-e59be3b4]:hover{border-color:#1e90ff")
      // console.log(paragraph)
      this.phone = country;
    },
    getDayOfWeek(d){
      if(this.locale.show_day_of_week){
        return dayOfWeek(d)
      }
      return ''
    },
    selectAdditionalGames(){
      //don't add the same booking slot
      let match = _.filter(this.checkInGames, _.matches({booking: this.selectedBooking}))
      if(match.length <= 0){
        this.checkInGames.push({
          game: this.selectedGame,
          booking: this.selectedBooking
        })
      }
      this.currentStep = 0
      if (window !== window.top) {
        this.$nextTick(() => {
          this.updateParentAboutHeightChange()
        });
      };
    },
    noAdditionalGames(){
      this.checkInGames.push({
        game: this.selectedGame,
        booking: this.selectedBooking
      })
      this.currentStep = 3

      if (window !== window.top) {
        this.$nextTick(() => {
          this.updateParentAboutHeightChange()
        });
      };
    },
    disabledDates(time) {
      var date = new Date();
      var previousDate = date.setDate(date.getDate() - 1);
      return time.getTime() < previousDate;
    },
    getFormattedDate(format, d){
      return formattedDate(format, d)
    },
    setToday(){
      let current = new Date()
      this.current = current.getFullYear() + '-' + (current.getMonth()+1) + '-' + current.getDate()
    },
    showPrevious(d){
      let current = new Date(d.split('-')[0], (d.split('-')[1])-1, d.split('-')[2])
      return current > this.today
    },
    changeDate(){
      if(this.waiverConfigurations.is_compact){
        this.getGames(this.waiverConfigurations.company_group_id)
      } else {
        this.getBookingsForGame(this.selectedGame.id)
      }

    },
    decrementDay(d){
      let current = new Date(d.split('-')[0], (d.split('-')[1])-1, d.split('-')[2])
      current.setDate(current.getDate() - 1)
      this.current = current.getFullYear() + '-' + (current.getMonth()+1) + '-' + current.getDate()
      if(this.waiverConfigurations.is_compact){
        this.getGames(this.waiverConfigurations.company_group_id)
      } else {
        this.getBookingsForGame(this.selectedGame.id)
      }
    },
    incrementDay(d){
      let current = new Date(d.split('-')[0], (d.split('-')[1])-1, d.split('-')[2])
      current.setDate(current.getDate() + 1)
      this.current = current.getFullYear() + '-' + (current.getMonth()+1) + '-' + current.getDate()

      if(this.waiverConfigurations.is_compact){
        this.getGames(this.waiverConfigurations.company_group_id)
      } else {
        this.getBookingsForGame(this.selectedGame.id)
      }

    },
    getUserInfo() {
      return new Promise ( function(resolve) {
        Auth.currentUserInfo()
          .then(data =>{
            resolve(data)
          })
          .catch(err => console.log(err))
      })
    },
    isMinor(is_minor){

      if(is_minor == 0){
        if (this.display.includes('Signature') || this.waiverConfigurations.waiver_text != null || this.waiverConfigurations.external_link != null){
          this.currentStep = 5
          if (window !== window.top) {
            this.$nextTick(() => {
              this.updateParentAboutHeightChange()
            });
          };
          return
        } else if (this.display.includes('Experience') || this.display.includes('Location') || this.display.includes('Source')){
          this.currentStep = 6
          if (window !== window.top) {
            this.$nextTick(() => {
              this.updateParentAboutHeightChange()
            });
          };
          return
        }
        this.currentStep = 7
      } else {
        this.has_minors = true
      }
      if (window !== window.top) {
        this.$nextTick(() => {
          this.updateParentAboutHeightChange()
        });
      };
    },
    setMinorData(){
      //validate
      let errors = 0
      for(let n in this.minors){
        if(this.requiredFields.includes('Child First Name') && (this.minors[n].first_name === '' || !this.minors[n].first_name)){
          this.minors[n].error_first_name = this.$t('checkin.errors.firstName')
          errors++
        } else {
          this.minors[n].error_first_name = ''
        }
        if(this.requiredFields.includes('Child Last Name') && (this.minors[n].last_name === '' || !this.minors[n].last_name)){
          this.minors[n].error_last_name = this.$t('checkin.errors.lastName')
          errors++
        } else {
          this.minors[n].error_last_name = ''
        }
        if(this.requiredFields.includes('Child Birthday') && (!this.minors[n].birthday || this.minors[n].birthday == '')){
          errors++
          this.minors[n].error_birthday = this.$t('checkin.errors.birthdayEmpty')
        } else if((this.minors[n].birthday != '') &&
          (!this.isValidDate(this.minors[n].birthday) || !this.isReasonableAge(this.minors[n].birthday))) {
          this.minors[n].error_birthday = this.$t('checkin.errors.birthday')
          errors++
        } else {
          this.minors[n].error_birthday = ""
        }

        this.x++
      }
      if(errors > 0){
        return
      }


      if (this.display.includes('Signature') || this.waiverConfigurations.waiver_text != null || this.waiverConfigurations.external_link != null){
        this.currentStep = 5
        if (window !== window.top) {
          this.$nextTick(() => {
            this.updateParentAboutHeightChange()
          });
        };
        return
      } else if (this.display.includes('Experience') || this.display.includes('Location') || this.display.includes('Source')){
        this.currentStep = 6
        if (window !== window.top) {
          this.$nextTick(() => {
            this.updateParentAboutHeightChange()
          });
        };
        return
      }
      this.currentStep = 7
      if (window !== window.top) {
        this.$nextTick(() => {
          this.updateParentAboutHeightChange()
        });
      };
    },
    addMinor(){
      this.minors.push(
        {
          fist_name: '',
          last_name: '',
          birthday: ''
        }
      )
    },
    getSignature () {
      if(this.$refs.signaturePad == undefined){
        return
      }
      var png =  this.$refs.signaturePad.saveSignature().data
      if (this.$refs.signaturePad.isEmpty()) {
        return ''
      } else {
        return png
      }
    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature()
    },
    setMarketingData(){
      let errors = 0
      if(this.selectedSource == '' && this.requiredFields.includes('Source')){
        errors++
        this.errs.source = this.$t('checkin.errors.source')
      } else {
        this.errs.source = ''
      }
      if(this.selectedExperience == '' && this.requiredFields.includes('Experience')){
        errors++
        this.errs.experience = this.$t('checkin.errors.experience')
      } else {
        this.errs.experience = ''
      }
      if(this.selectedState == '' && this.requiredFields.includes('Location')){
        errors++
        this.errs.location.state = this.$t('checkin.errors.location')
      } else {
        this.errs.location.state = ''
      }
      if(this.city == '' && this.requiredFields.includes('Location')){
        errors++
        this.errs.location.city = this.$t('checkin.errors.location')
      } else {
        this.errs.location.city = ''
      }
      if(errors > 0){
        return
      }
      this.currentStep = 7
      if (window !== window.top) {
        this.$nextTick(() => {
          this.updateParentAboutHeightChange()
        });
      };
    },
    setSignatureData(){
      this.signature = this.getSignature()
      let errors = 0

      if(this.signature == ''){
        errors++
        this.errs.signature = this.$t('checkin.errors.signature')
      } else {
        this.errs.signature = ''
      }
      if(this.waiverConfigurations.external_link != null && this.conditions == false){
        errors++
        this.errs.conditions = this.$t('checkin.errors.tos')
      } else {
        this.errs.conditions = ''
      }
      if(errors > 0){
        return
      }
      if (this.display.includes('Experience') || this.display.includes('Location') || this.display.includes('Source')){
        this.currentStep = 6
        if (window !== window.top) {
          this.$nextTick(() => {
            this.updateParentAboutHeightChange()
          });
        };
        return
      }
      this.currentStep = 7
      if (window !== window.top) {
        this.$nextTick(() => {
          this.updateParentAboutHeightChange()
        });
      };
    },
    getSource(){
      if(this.selectedSource.value === 'Other'){
        return this.otherSource.toLowerCase()
      } else {
        if(this.selectedSource.hasOwnProperty('value')){
          return this.selectedSource.value.toLowerCase()
        }
        return ''
      }
    },
    async confirmCheckin(){
      let b = this.user.birthday
      if(this.user.birthday != undefined || this.user.birthday != ''){
        b =  b.split('/')[2] + '-' + b.split('/')[0] + '-' + b.split('/')[1]
      }

      for(let n in this.minors){
        if(!this.minors[n].first_name && !this.minors[n].last_name){
          this.minors.splice(n, 1)
        }
      }
      for(let m in this.minors){
        if(this.minors[m].birthday != undefined || this.minors[m].birthday != ''){
          this.minors[m].birthday =  this.minors[m].birthday.split('/')[2] + '-' + this.minors[m].birthday.split('/')[0] + '-' + this.minors[m].birthday.split('/')[1]
        }
      }

      this.loading = true
      let url = API_LOCATION + 'checkin'
      this.user.birthday = b

      let data = {
        company_id: this.waiverConfigurations.company_id,
        user: this.user,
        bookings: this.checkInGames,
        minors: this.minors,
        signature: this.signature,
        picture_optout: this.picture_optout,
        email_optout: this.email_optout,
        experience: this.selectedSource.hasOwnProperty('value') ? this.selectedSource.value.toLowerCase()  : '',
        location_city: this.city,
        location_state: this.selectedState,
        source: this.getSource(),
        not_participating: this.not_participating,
        locale: this.$i18n.locale
      }


      this.axios.post(url, data)
        .then(response => {
          this.loading = false
          this.disabled = true
          swal({
            title: this.$t('checkin.swals.congratulations'),
            text: this.$t('checkin.swals.allSet'),
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#fd5d93',
            confirmButtonText: 'OK'
          }).then((response)=> {
            this.$router.go()
          })
        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
          if(err.response.data == 'Waiver record already exists'){
            swal({
              title: this.$t('checkin.swals.error'),
              text: this.$t('checkin.swals.alreadyCheckedIn'),
              type: 'error',
              showCancelButton: false,
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'OK'
            }).then((response)=> {
              this.$router.go()
            })
          }
        })
    },
    getDate(d){
      return date(d)
    },
    getTime(format, d){
      return time(format, d)
    },
    getGames (id) {
      let url = API_LOCATION + 'company-groups/' + id + '/games?date=' + this.current
      axios.get(url)
        .then(response => {
          this.gameOptions = response.data
          for(let n in this.gameOptions){
            this.gameOptions[n].slots = _.orderBy(JSON.parse(this.gameOptions[n].slots), 'start_time')

            for(let m in this.gameOptions[n].slots){
              for(let k in this.checkInGames){
                if(this.gameOptions[n].slots[m].id === this.checkInGames[k].booking.id){
                  this.gameOptions[n].slots[m].selected = true
                }
              }
            }
          }
          this.j++

          if (window !== window.top) {
            this.$nextTick(() => {
              this.updateParentAboutHeightChange()
            });
          };
        })
        .catch(err => {
          console.log(err)
        })
    },
    isEmailValid(emailString) {
      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(emailString);
    },
    getWaiverConfigurations(){
      this.loading = true
      var code = this.$route.params.company
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/waiver'

      axios.get(url)
        .then(response => {
          this.notFound = false
          this.waiverConfigurations = response.data.config[0]
          this.fields = response.data.fields
          this.sections = response.data.sections
          this.locale = response.data.locale
          for(let n in this.fields){
            if(this.fields[n].is_active == 1){
              this.display.push(this.fields[n].name)
              if(this.fields[n].is_required){
                this.requiredFields.push(this.fields[n].name)
              }
            }
          }
          if(this.locale.country === 'GB'){
            this.phone_placeholder = '07700 900000'
          }
          if(this.locale.country !== 'US'){
            this.state_placeholder = this.$t('checkin.country')
          }
          if(this.locale.show_day_of_week){
            this.day_of_week = 'ddd, '
          }

          //Fix year for el date picker
          this.locale.date_format = this.locale.date_format.replaceAll('Y', 'y')
          this.locale.date_format = this.locale.date_format.replaceAll('D', 'd')

          this.loading = false
          this.getGames(this.waiverConfigurations.company_group_id)
        })
        .catch(err => {
          this.loading = false
          this.notFound = true
          console.log(err)
        })
    },
    selectGame(game){
      this.currentStep = 1
      this.selectedGame = game
      this.getBookingsForGame(this.selectedGame.id)

    },
    async getBookingsForGame(gameId){
      this.loading = true
      let url = API_LOCATION + 'games/' + gameId + '/bookings?from=' + this.current + `&to=` + this.current
      axios.get(url)
        .then(response => {
          this.loading = false
          this.bookings = response.data.booking_slots
          if(this.bookings.length <= 0){
            this.noBookingsAvailable = true
          } else {
            this.noBookingsAvailable = false
          }

        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    login(){
      let redirect = '/checkin/' + this.$route.params.company
      localStorage.setItem('redirect', redirect)
      this.$router.push('/login')
    },
    register(){
      let redirect = '/checkin/' + this.$route.params.company
      localStorage.setItem('redirect', redirect)
      this.$router.push('/register')
    },
    toProfile(){
      this.$router.push('/settings/profile')
    },
    async isAuthenticated(){
      let token = await this.getAuthToken()
      if(token == 'No current user'){
        this.is_authenticated = false
      } else {
        this.is_authenticated = true
        this.user = JSON.parse(localStorage.getItem('user'))
      }
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    setUserData(){
      let errors = 0
      if(this.isEmailValid(this.user.email) == false){
        errors++
        this.errs.email = this.$t('checkin.errors.email')
      } else {
        this.errs.email = ''
      }
      if(this.user.first_name == '' && this.requiredFields.includes('First Name')){
        errors++
        this.errs.first_name = this.$t('checkin.errors.firstName')
      } else {
        this.errs.first_name = ''
      }
      if(this.user.last_name == '' && this.requiredFields.includes('Last Name')){
        errors++
        this.errs.last_name = this.$t('checkin.errors.lastName')
      } else {
        this.errs.last_name = ''
      }
      if((!this.isValidDate(this.user.birthday)
          || !this.isReasonableAge(this.user.birthday) || this.isUnderEighteen(this.user.birthday))
        && this.requiredFields.includes('Birthday')){
        errors++
        this.errs.birthday = this.$t('checkin.errors.birthday')
      } else if(this.user.birthday !== '' && (!this.isValidDate(this.user.birthday)
        || !this.isReasonableAge(this.user.birthday) || this.isUnderEighteen(this.user.birthday))) {
        this.errs.birthday = this.$t('checkin.errors.birthday')
      } else {
        this.errs.birthday = ''
      }
      if(this.user.phone == '' && this.requiredFields.includes('Phone')){
        errors++
        this.errs.phone = this.$t('checkin.errors.phone')
      } else {
        this.errs.phone = ''
      }
      if(errors > 0){
        return
      }
      if(this.display.includes('Child First Name') || this.display.includes('Child Last Name') || this.display.includes('Child Birthday')){
        this.currentStep = 4
        if (window !== window.top) {
          this.$nextTick(() => {
            this.updateParentAboutHeightChange()
          });
        };
        return
      } else if (this.display.includes('Signature') || this.waiverConfigurations.waiver_text != null || this.waiverConfigurations.external_link != null){
        this.currentStep = 5
        if (window !== window.top) {
          this.$nextTick(() => {
            this.updateParentAboutHeightChange()
          });
        };
        return
      } else if (this.display.includes('Experience') || this.display.includes('Location') || this.display.includes('Source')){
        this.currentStep = 6
        if (window !== window.top) {
          this.$nextTick(() => {
            this.updateParentAboutHeightChange()
          });
        };
        return
      }
      this.currentStep = 7
      if (window !== window.top) {
        this.$nextTick(() => {
          this.updateParentAboutHeightChange()
        });
      };
    },
    unselectBookingCompactView(slot){
      slot.selected = false

      let index = 0
      for(let i = 0; i < this.checkInGames.length - 1; i++){
        if(slot.id === this.checkInGames[i].booking.id){
          index = i
        }
      }
      this.checkInGames.splice(index, 1)
    },
    selectMultipleCompact(){
      if(this.checkInGames.length <= 0){
        return
      }
      this.currentStep = 3
    },
    selectBookingCompactView(slot, game){
      if(this.waiverConfigurations.multiple_games == 1){
        slot.selected = true
        this.checkInGames.push({
          game: game,
          booking: slot
        })
      } else {
        this.checkInGames = [{
          game: game,
          booking: slot
        }]
        this.currentStep = 3
      }

    },
    async selectBookingDate(booking){

      this.selectedBooking = booking
      if(this.waiverConfigurations.multiple_games == 1){
        this.currentStep = 2
      } else {
        if(this.waiverConfigurations.multiple_games == false){
          this.checkInGames = [{
            game: this.selectedGame,
            booking: this.selectedBooking
          }]

        } else {
          this.checkInGames.push({
            game: this.selectedGame,
            booking: this.selectedBooking
          })
        }
        this.currentStep = 3
      }
      if (window !== window.top) {
        this.$nextTick(() => {
          this.updateParentAboutHeightChange()
        });
      };
    },
    async getUser(){
      let user = await this.getUserInfo()
      let redirect = '/checkin/' + this.$route.params.company
      if(user == null){
        localStorage.setItem('redirect', redirect)
      }
    },
    updateParentAboutHeightChange() {
      const height = this.$el.scrollHeight; //
      window.parent.postMessage({action: 'updateHeight', height: height + 20}, '*');
    },
  },
  mounted() {
    if(this.$route.name === 'Checkin-kiosk'){
      this.is_kiosk = true
    }

    this.setToday()
    this.getUser()
    this.getWaiverConfigurations()
  }
}
</script>

<style scoped>
.game-button{
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;

}
.sig-pad {
  border: #344675 2px solid;
  border-radius: 5px;
}
textarea
{
  width:100%;
  margin:5px 0;
  padding:1%;
}


.empty-bookings{
  text-align: center;
  padding: -10px 5px -10px;
  /*margin-bottom: -30px;*/
  border: 1px solid #e44cc4;
  border-radius: .1875rem;
  min-height: 90px;
  flex: 1;
}
.empty-bookings i{
  font-size: 1em;
  margin: 0px 0 -10px;
}

.empty-bookings p{
  padding: 7px;
  /*margin-top: 30px;*/
  font-size: 0.8em;
}


</style>
