<template>
  <div id="root" :style="{ 'background-color': bss.background_color, 'overflow': 'auto',
  'position' : 'absolute',
  'top' : '0px',
    'right' : '0px',
  'bottom':'0px',
  'left':'0px', 'overflow-x': 'hidden'}">

    <div v-if="status" style="margin-top: 100px" class="row justify-content-center">
      <div class="col-lg-6 col-10">
        <card :style="{ 'background-color': bss.card_color}">
          <div class="row mt-4 ml-1 mr-1 justify-content-center">
            <h3 :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" class="card-title">Thank you for booking with {{bss.company_name}}!</h3>
          </div>
          <div class="row justify-content-center">
            <div class="col">
              <hr :style="{ 'background-color': bss.text_color_card}">
              <div class="row mt-3 mr-2 justify-content-end">
                <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">Order reference: #{{orderNumber}}</p>
              </div>
              <div class="row mr-2 justify-content-end">
                <p v-if="payment_status === 'paid'" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">Payment status: <span class="text-success">PAID</span> </p>
                <p v-if="payment_status === 'partially_paid'" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">Payment status: <span class="text-warning">PARTIALLY PAID</span> </p>
                <p v-if="payment_status === 'unpaid'" :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}">Payment status: <span class="text-danger">UNPAID</span> </p>
              </div>

              <div>
                <div v-if="gift_cards.quantity >0" class="row mt-3">
                  <div class="col-12">
                    <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">
                      <span >Gift card</span> for the amount of {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(gift_cards.total))}} {{getCurrencyCode(locale.currency)}}
                    </p>
                  </div>
                  <div class="col-12">
                    <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">Quantity: {{gift_cards.quantity}}
                    </p>
                  </div>
                </div>
                <div v-if="bookings.length > 0" class="row mt-3" v-for="(c, index) in bookings" :key="'RP' + index">
                  <div class="col-12">
                    <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">
                      <span >{{c.game_name}}</span> at {{getTime(locale.time_format, c.start_time)}}, {{getDayOfWeek(c.booking_date)}} {{getFormattedDate(locale.date_format, c.booking_date)}}
                    </p>
                  </div>
                  <div class="col-12">
                    <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">Quantity: {{c.quantity}}
                    </p>
                  </div>
                </div>
              </div>
              <hr :style="{ 'background-color': bss.text_color_card}">
              <div class="row">
                <div class="col-12 text-right">
                  <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">Total amount: {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(total))}} {{getCurrencyCode(locale.currency)}}</p>
                  <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">Amount paid: {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(paid))}} {{getCurrencyCode(locale.currency)}}</p>
                  <p :style="{'font-family': bss.font_family_card, 'color': bss.text_color_card}" style="font-size: medium">Amount due: {{getCurrencySymbol(locale.currency)}}{{getNumberFormat(getAmount(due))}} {{getCurrencyCode(locale.currency)}}</p>

                </div>
              </div>
              <div class="row justify-content-center mt-3" v-if="!bss.auto_redirect">
                <div class="col-12 col-lg-6">
                  <base-button :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color, 'width': '100%' }"  v-on:click="goHome()" simple>
                    {{ bss.redirect_button_text }}</base-button>
                  <base-button :style="{ 'font-family': bss.font_family_card, 'border-color': bss.button_color, 'color': bss.button_color, 'width': '100%' }"  v-on:click="goToCheckIn()" class="button-wide" simple>Check-in</base-button>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>

</div>
</template>

<script>
import {API_LOCATION} from "@/api-config";
import axios from "axios";
import currencies from "@/assets/currencies.json";
import {dayOfWeek, formattedDate, time} from "@/plugins/dateFormatter";
import swal from "sweetalert2";

export default {
  name: "BookingReceipt",
  data(){
    return {
      status: 1,
      bss: '',
      loading: false,
      locale: '',
      currencies: currencies.currencies,
      orderNumber: '',
      hash: '',
      timestamp: '',
      bookings: [],
      gift_cards: [],
      payment_status: '',
      total: 0,
      paid: 0,
      due: 0
    }
  },
  methods: {
    getAmount(integer){
      if(integer == null){
        return
      }
      //this.getNumberFormat(parseFloat(integer).toFixed(2))
      return parseFloat(integer).toFixed(2)
    },
    goHome(){
      window.location.href = this.bss.redirect_url
    },
    getCurrencyCode(code){
      if(this.locale.currency_format === 1){
        return code
      }
      return ''
    },
    getNumberFormat(value){
      if(this.locale.number_format === 1){
        value = value.toLocaleString(undefined, { minimumFractionDigits: 2 }).replace('.', ',')
      }
      return value
    },
    getTime(format, d){
      return time(format, d)
    },
    goToCheckIn(){
      let code = this.$route.query.company
      let path = '/checkin/' + code
      if(this.bss.display_waiver_link){
        path = '/waiver/' + this.hash
      }
      this.$router.push(path)
    },
    getCurrencySymbol(code){
      if(this.locale.currency_format === 0){
        let c = _.find(this.currencies, { code: code });
        return c.symbol
      }
    },
    getFormattedDate(format, d){
      return formattedDate(format, d)
    },
    getDayOfWeek(d){
      if(this.locale.show_day_of_week){
        return dayOfWeek(d)
      }
      return ''
    },
    getBss(){
      let code = this.$route.query.code
      let url = API_LOCATION + 'bookings/company-groups/' + code + '/booking'

      axios.get(url)
        .then(response => {
          this.notFound = false
          this.bss = response.data.config[0]
          this.locale = response.data.locale

          if(this.locale.show_day_of_week){
            this.day_of_week = 'ddd, '
          }

        })
        .catch(err => {
          this.notFound = true
          console.log(err)
        })
    },
    async getTransactionData(){
      let code = this.$route.query.code
      let t_id = this.$route.query.t_id
      let g_id = this.$route.query.g_id
      let url = API_LOCATION + 'yappy/success?code=' + code + '&t_id=' + t_id + '&g_id=' + g_id

      axios.get(url)
        .then(response => {
          console.log(response.data)

          this.orderNumber = response.data.orderId
          this.timestamp = response.data.timestamp
          this.hash = response.data.hash
          this.bookings = response.data.bookings
          this.gift_cards = response.data.gift_cards
          this.payment_status = response.data.status
          this.total = response.data.total
          this.due = response.data.due
          this.paid = response.data.paid

          if(this.locale.show_day_of_week){
            this.day_of_week = 'ddd, '
          }

        })
        .catch(err => {
          if(err.response.status == 500){
            swal('Error!', "The link has expired", 'error')
          }
          console.log(err)
        })
    },
    async processFail(){
      this.status = 0
      let code = this.$route.query.code
      let t_id = this.$route.query.t_id
      let g_id = this.$route.query.g_id
      let url = API_LOCATION + 'yappy/fail?code=' + code + '&t_id=' + t_id + '&g_id=' + g_id

      axios.get(url)
        .then(response => {
          swal({
            title: 'Error',
            text: "Something went wrong with your transaction. Please contact the business.",
            type: 'error'
          }).then((response)=> {
            window.location = 'https://offthecouch.io/book/' + code
          })
          swal('Error!', err.response.data, 'error')
        })
        .catch(err => {
          if(err.response.status == 500){
            swal('Error!', err.response.data, 'error')
          }
          console.log(err)
        })
    }
  },
  mounted() {
    this.getBss()
    let type = this.$route.query.type
    if(type === 'fail'){
      this.processFail()
    } else if (type === 'success'){
      this.getTransactionData()
    }
  }
}
</script>

<style scoped>

</style>
