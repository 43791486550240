<template>
  <div :style="{ 'background-color': waiverConfigurations.background_color, 'overflow': 'auto',
  'position' : 'absolute',
  'top' : '0px',
    'right' : '0px',
  'bottom':'0px',
  'left':'0px'}">

    <link v-if="!loading" rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + waiverConfigurations.font_family">
    <link v-if="!loading" rel="stylesheet" :href="'https://fonts.googleapis.com/css?family=' + waiverConfigurations.font_family_card">

    <!--    Loader-->
    <loading-panel :loading="loading"></loading-panel>
    <not-found-page v-show="notFound"></not-found-page>

    <div class="container" v-show="!notFound">
      <div class="row">
        <div class="col-md-6 mt-5 mb-4 ml-auto mr-auto text-center">
          <img :height="waiverConfigurations.logo_height" :width="waiverConfigurations.logo_width" :style="{'object-fit': waiverConfigurations.logo_fit}" :src="waiverConfigurations.logo" alt="logo">
          <h4 :style="{'font-family': waiverConfigurations.font_family, 'color': waiverConfigurations.text_color}" class="mt-3 description">
            {{waiverConfigurations.main_message}}
          </h4>
        </div>
      </div>
    </div>

    <div v-show="!notFound">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <h4 v-if="!checked_in" :style="{'font-family': waiverConfigurations.font_family, 'color': waiverConfigurations.text_color}" class="mt-3 description">
            {{$t('checkin.yourBookings')}}
          </h4>
          <h4 v-if="checked_in" :style="{'font-family': waiverConfigurations.font_family, 'color': waiverConfigurations.text_color}" class="mt-3 description">
            {{$t('checkin.allSet')}}
          </h4>
        </div>
      </div>
    </div>

<!--    Booking info-->
    <div :style="{'font-family': waiverConfigurations.font_family_card}" class="row justify-content-center">
      <div class="col-lg-6">
        <card :style="{ 'background-color': waiverConfigurations.card_color}">
          <div v-for="(b, i) in bookings" :key="'B' + i">
            <div class="row mt-2">
              <div v-if="bookings.length > 1 && !checked_in" class="col-lg-1 col-1 text-right">
                <base-checkbox :border-color="waiverConfigurations.button_color" :accent-color="waiverConfigurations.button_color"
                               :check-mark-color="waiverConfigurations.card_color"
                               v-model="bookings[i].included"></base-checkbox>
              </div>
              <div class="col-lg-2 col-4" v-if="(bookings[i].included && checked_in) || !checked_in">
                <img height="100" :src="b.url"/>
              </div>
              <div class="col-lg-5 col" v-if="(bookings[i].included && checked_in) || !checked_in">
                <h4 :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">{{b.name}}</h4>
                <p :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">
                  <i class="tim-icons icon-calendar-60 mr-2"></i>{{getDayOfWeek( b.booking_date)}} {{getFormattedDate(locale.date_format, b.booking_date)}}
                  <i class="tim-icons icon-time-alarm mr-2"></i>{{getTime(locale.time_format, b.start_time)}}
                </p>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>

    <div v-if="!checked_in" v-show="!notFound">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <h4 :style="{'font-family': waiverConfigurations.font_family, 'color': waiverConfigurations.text_color}" class="mt-3 description">
            {{$t('checkin.waiver')}}
          </h4>
        </div>
      </div>
    </div>

      <div  v-if="!checked_in" :style="{'font-family': waiverConfigurations.font_family_card}" class="row justify-content-center">
        <div class="col-lg-6">
          <card :style="{ 'background-color': waiverConfigurations.card_color}">
<!--            Waiver-->
            <span v-html="waiverConfigurations.waiver_text"></span>
            <!--            <textarea :style="{'background-color': 'white'}" v-if="waiverConfigurations.waiver_text" disabled rows="30" v-model="waiverConfigurations.waiver_text"></textarea>-->
            <div class="mt-2" v-if="waiverConfigurations.external_link">
              <base-input :secondary-color="waiverConfigurations.text_color_card" :error="errs.conditions">
                <base-checkbox :border-color="waiverConfigurations.button_color" :accent-color="waiverConfigurations.button_color"
                               :check-mark-color="waiverConfigurations.card_color" v-model="conditions">
                  <span :style="{'color': waiverConfigurations.text_color_card}">{{$t('checkin.agreeToToS')}}
                    <a :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.link_color}" target="_blank" :href="waiverConfigurations.external_link">
                      {{$t('checkin.termsAndConditions')}}
                    </a>.</span>
                </base-checkbox>
              </base-input>

              <p class="text-danger small" v-if="errs.conditions==true">{{$t('checkin.errors.mustAcceptToS')}}</p>
            </div>
            <div v-if="display.includes('Signature')" class="row mt-3 justify-content-between">
              <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                <h6 :style="{'color': waiverConfigurations.text_color_card}" class="mt-3">{{$t('checkin.signature')}}</h6>
              </div>
              <div class="mr-3 ml-3">
                <base-button :style="{'font-family': waiverConfigurations.font_family_card, 'border-color': waiverConfigurations.button_color,'color': waiverConfigurations.button_color}" size="sm" align="left" simple v-on:click="clearSignature()" type="secondary">
                  {{$t('checkin.clearSignature')}}</base-button>
              </div>
            </div>

            <div class="row" v-if="display.includes('Signature')">

              <div class=" col-lg-12">
                <base-input :secondary-color="waiverConfigurations.text_color_card" :error="errs.signature">
                  <VueSignaturePad :custom-style="background_sig" :style="{'border-color': waiverConfigurations.button_color}" class="sig-pad" height="200px" ref="signaturePad" />
                </base-input>

<!--                <p :style="{'color': waiverConfigurations.text_color_card}" class="text-danger small" v-if="errs.signature==true">Signature cannot be empty</p>-->
              </div>
            </div>
            <div v-if="display.includes('Photo Opt Out')" class="row">
              <div class="col">
                <base-checkbox :border-color="waiverConfigurations.button_color" :accent-color="waiverConfigurations.button_color"
                               :check-mark-color="waiverConfigurations.card_color" class="ml-1" v-model="picture_optout">
                  <span :style="{'color': waiverConfigurations.text_color_card}">
                    {{$t('checkin.doNotSharePhotos')}}</span>
                </base-checkbox>
              </div>
            </div>
            <div v-if="display.includes('Email Opt Out')" class="row">
              <div class="col-12 col-lg-12">
                <base-checkbox :border-color="waiverConfigurations.button_color" :accent-color="waiverConfigurations.button_color"
                               :check-mark-color="waiverConfigurations.card_color" class="ml-1" v-model="email_optout">
                  <span :style="{'color': waiverConfigurations.text_color_card}">
                    {{$t('checkin.doNotIncludeInMailingLists')}}</span>
                </base-checkbox>
              </div>
            </div>

<!--            User info-->
            <div class="row mt-3 justify-content-center">
              <div class="col-lg-6 col-12" v-if="display.includes('First Name')">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{$t('checkin.firstName')}}
                  <span v-if="requiredFields.includes('First Name')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :secondary-color="waiverConfigurations.text_color_card" :error="errs.first_name" :input-style="{'color': waiverConfigurations.text_color_card}" :placeholder="$t('checkin.firstName')"
                            v-model="user.first_name"></base-input>
              </div>
              <div class="col-lg-6 col-12" v-if="display.includes('Last Name')">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{$t('checkin.lastName')}}
                  <span v-if="requiredFields.includes('Last Name')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :secondary-color="waiverConfigurations.text_color_card" :error="errs.last_name" :input-style="{'color': waiverConfigurations.text_color_card}" :placeholder="$t('checkin.lastName')"
                            v-model="user.last_name"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-12" v-if="display.includes('Birthday')">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{$t('checkin.birthday')}}
                  <span v-if="requiredFields.includes('Birthday')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :secondary-color="waiverConfigurations.text_color_card" :error="errs.birthday" :input-style="{'color': waiverConfigurations.text_color_card}"
                            v-mask="'##/##/####'" placeholder="mm/dd/yyyy"
                            v-model="user.birthday"></base-input>
              </div>
              <div class="col-lg-6 col-12" v-if="display.includes('Phone')">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{$t('checkin.phone')}}
                  <span v-if="requiredFields.includes('Phone')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :secondary-color="waiverConfigurations.text_color_card" :error="errs.phone" :input-style="{'color': waiverConfigurations.text_color_card}"
                            :placeholder="phone_placeholder"
                            v-model="user.phone"></base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6" v-if="display.includes('Email')">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{$t('checkin.email')}}
                  <span v-if="requiredFields.includes('Email')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :secondary-color="waiverConfigurations.text_color_card" :error="errs.email" :input-style="{'color': waiverConfigurations.text_color_card}" v-model="user.email" :placeholder="$t('checkin.email')"></base-input>
              </div>
            </div>

<!--            Childen-->
            <div class="row mb-2" v-if="!add_minors && (display.includes('Child First Name') || display.includes('Child Last Name') || display.includes('Child Birthday'))">
              <div class="col">
                <base-button :style="{ 'font-family': waiverConfigurations.font_family_card, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }" simple v-on:click="add_minors = true">{{$t('checkin.addChildren')}}</base-button>
              </div>
            </div>
            <div v-if="add_minors" class="row justify-content-between" v-for="(m, i) in minors" :key="'A' + i">
              <div class="col-lg-2 mt-4">
                <p class="text-lg-right" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">
                  {{$t('checkin.child')}} {{i+1}}</p>
              </div>
              <div class="col-10 col-lg-3" v-if="display.includes('Child First Name')" style="padding-right: 0px;">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{$t('checkin.firstName')}}
                  <span v-if="requiredFields.includes('Child First Name')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :secondary-color="waiverConfigurations.text_color_card" :error="errs.child[i].first_name" :input-style="{'color': waiverConfigurations.text_color_card}"  :placeholder="$t('checkin.firstName')"
                            v-model="m.first_name"></base-input>
              </div>
              <div class="col-10 col-lg-3" v-if="display.includes('Child Last Name')" style="padding-right: 0px;">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{$t('checkin.lastName')}}
                  <span v-if="requiredFields.includes('Child Last Name')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>

                </label>
                <base-input :secondary-color="waiverConfigurations.text_color_card" :error="errs.child[i].last_name" :input-style="{'color': waiverConfigurations.text_color_card}" :placeholder="$t('checkin.lastName')"
                            v-model="m.last_name"></base-input>
              </div>
              <div class="col-10 col-lg-3" v-if="display.includes('Child Birthday')" style="padding-right: 0px;">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{$t('checkin.birthday')}}
                  <span v-if="requiredFields.includes('Child Birthday')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :secondary-color="waiverConfigurations.text_color_card" :error="errs.child[i].birthday" :input-style="{'color': waiverConfigurations.text_color_card}"
                            v-mask="'##/##/####'" placeholder="mm/dd/yyyy"
                            v-model="m.birthday"></base-input>
              </div>
              <div class="col-1 mr-4 mr-lg-0" >
                <i :style="{ 'color': waiverConfigurations.button_color }" v-on:click="removeChild(i)"
                   style="cursor: pointer; margin-top: 37px; padding-left: 0px"
                   class="tim-icons icon-simple-remove"></i>
              </div>
            </div>
            <div class="row mb-2" v-if="add_minors">
              <div class="col">
                <base-button :style="{ 'font-family': waiverConfigurations.font_family_card, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }" simple v-on:click="addMinor()">{{$t('checkin.addAnotherChild')}}</base-button>
              </div>
            </div>
            <div class="row mb-2" v-if="add_minors && minors.length > 0 && waiverConfigurations.show_participation_field">
              <div class="col">
                <base-checkbox :border-color="waiverConfigurations.button_color" :accent-color="waiverConfigurations.button_color"
                               :check-mark-color="waiverConfigurations.card_color"
                               v-model="not_participating">
                  <span :style="{'color': waiverConfigurations.text_color_card}">{{ waiverConfigurations.participation_field_text }}
                  </span>
                </base-checkbox>
              </div>
            </div>

<!--            Marketing-->
            <div v-if="display.includes('Source')" class="row">
              <div class="col-lg-6">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{$t('checkin.howDidYouHearAboutUs')}}
                  <span v-if="requiredFields.includes('Source')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :secondary-color="waiverConfigurations.text_color_card" placeholder="Select">
                  <base-dropdown :style="{'font-family': waiverConfigurations.font_family_card }"
                                 title-classes="form-group form-control" :text-color="waiverConfigurations.button_color"
                                 :font-family="waiverConfigurations.font_family_card"
                                 :title="selectedSource.label"
                  >
                    <a v-for="(s, i) in sources"  :style="{'font-family': waiverConfigurations.font_family_card }"
                       style="white-space: normal; overflow-wrap: break-word;"
                       class="dropdown-item" href="#" :key="'DOJ' + i" v-on:click="selectedSource = s">
                      {{ s.label }}</a>
                  </base-dropdown>
                </base-input>

              </div>
              <div v-if="selectedSource === 'Other'" class="col-lg-6">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{$t('checkin.pleaseSpecify')}}
                </label>
                <base-input :secondary-color="waiverConfigurations.text_color_card" v-model="otherSource" :input-style="{'color': waiverConfigurations.text_color_card}" :placeholder="$t('checkin.source')"
                ></base-input>
              </div>
            </div>
            <div v-if="display.includes('Experience')" class="row">
              <div class="col-lg-6">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{$t('checkin.experience')}}
                  <span v-if="requiredFields.includes('Experience')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :secondary-color="waiverConfigurations.text_color_card" :placeholder="$t('checkin.select')">
                  <base-dropdown :style="{'font-family': waiverConfigurations.font_family_card }"
                                 title-classes="form-group form-control" :text-color="waiverConfigurations.button_color"
                                 :font-family="waiverConfigurations.font_family_card"
                                 :title="selectedExperience.label"
                  >
                    <a v-for="(e, i) in experiences"  :style="{'font-family': waiverConfigurations.font_family_card }"
                       style="white-space: normal; overflow-wrap: break-word;"
                       class="dropdown-item" href="#" :key="'EXX' + i" v-on:click="selectedExperience = e">
                      {{ e.label }}</a>
                  </base-dropdown>
                </base-input>
              </div>
            </div>
            <div v-if="display.includes('Location')" class="row">
              <div class="col-lg-6">
                <label :style="{'color': waiverConfigurations.text_color_card}">{{$t('checkin.whereAreYouFrom')}}
                  <span v-if="requiredFields.includes('Location')" :style="{'font-family': waiverConfigurations.font_family_card, 'color': waiverConfigurations.text_color_card}">*</span>
                </label>
                <base-input :secondary-color="waiverConfigurations.text_color_card" :error="errs.location.city" style="margin-bottom: 0px;" placeholder="City" v-model="city" :input-style="{'color': waiverConfigurations.text_color_card}">
                </base-input>
              </div>
              <div class="col-lg-6">
                <label :style="{'color': waiverConfigurations.text_color_card}"> &nbsp;
<!--                  <span v-if="requiredFields.includes('Location')" class="text-danger">*</span>-->
                </label>
                <base-input :secondary-color="waiverConfigurations.text_color_card" :error="errs.location.state"  style="margin-bottom: 0px;" :placeholder="state_placeholder" v-model="selectedState" :input-style="{'color': waiverConfigurations.text_color_card}">
                </base-input>
              </div>
            </div>
<!--            Submit -->
            <div class="row mt-3 justify-content-center">
              <div class="col text-center">
                <base-button simple  v-on:click="confirmCheckin()" :disabled="disabled"
                             class="game-button"
                             :style="{'font-family': waiverConfigurations.font_family_card, 'border-color': waiverConfigurations.button_color, 'color': waiverConfigurations.button_color }">
                  <span class="ml-1 mt-1">{{$t('checkin.submitWaiver')}}</span>
                </base-button>
              </div>
            </div>
          </card>
        </div>
      </div>
  </div>
</template>

<script>
import {API_LOCATION} from 'src/api-config'
import { BaseAlert } from 'src/components';
import {Select, Option, DatePicker, TimePicker, TimeSelect} from 'element-ui'
import NotFoundPage from "../../pages/GeneralViews/NotFoundPage";
const axios = require('axios')
import {date, time, formattedDate, dayOfWeek} from '/src/plugins/dateFormatter'
import {Auth} from "aws-amplify";
import swal from "sweetalert2";

const disabledDate = (time) => {
  return time.getTime() > Date.now()
}

export default {
  name: "ShareableWaiver",
  components: {
    NotFoundPage,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [TimePicker.name]: TimePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    BaseAlert
  },
  data() {
    return {
      not_participating: false,
      hash: '',
      add_minors: false,
      checked_in: false,
      bookings: [],
      state_placeholder: 'State',
      phone_placeholder: '(999) 999-9999',
      phone: '',
      background_sig: { 'background-color': 'white'},
      loading: false,
      locale: '',
      has_minors: false,
      day_of_week: '',
      noBookingsAvailable: false,
      picture_optout: false,
      email_optout: false,
      selectedBooking: '',
      checkInGames: [],
      notFound: '',
      is_authenticated: false,
      waiverConfigurations: '',
      conditions: false,
      current: '',
      currentStep: 0,
      fields: [],
      sections: [],
      display: [],
      signature: '',
      selectedExperience: '',
      selectedSource: '',
      otherSource: '',
      selectedState: '',
      city: '',
      disabled: false,
      experiences: [
        {
          value: this.$t('checkin.exp.beginner'),
          label: this.$t('checkin.exp.beginnerDesc'),
        },
        {
          value: this.$t('checkin.exp.intermediate'),
          label: this.$t('checkin.exp.intermediateDesc'),
        },
        {
          value: this.$t('checkin.exp.expert'),
          label: this.$t('checkin.exp.expertDesc'),
        },
        {
          value: this.$t('checkin.exp.master'),
          label: this.$t('checkin.exp.masterDesc'),
        },
        {
          value: this.$t('checkin.exp.grandmaster'),
          label: this.$t('checkin.exp.grandmasterDesc'),
        }
      ],
      sources: [
        {
          value: 'Google',
          label: 'Google',
        },
        {
          value: 'Instagram',
          label: 'Instagram',
        },
        {
          value: 'Facebook',
          label: 'Facebook',
        },
        {
          value: 'Yelp',
          label: 'Yelp',
        },
        {
          value: this.$t('checkin.sources.wordOfMouth'),
          label: this.$t('checkin.sources.wordOfMouth'),
        },
        {
          value: this.$t('checkin.sources.other'),
          label: this.$t('checkin.sources.other'),
        },
      ],
      states: ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming'],
      user: {
        email: '',
        phone: '',
        first_name: '',
        last_name: '',
        birthday: ''
      },
      minors: [{
        first_name: '',
        last_name: '',
        birthday: ''
      }],
      errs: {
        email: '',
        first_name: '',
        last_name: '',
        birthday: '',
        phone: '',
        signature: '',
        conditions: '',
        source: '',
        location: {
          city: '',
          state: ''
        },
        child: [
          {
            first_name: '',
            last_name: '',
            birthday: ''
          }
        ],
        experience: ''
      },
      requiredFields: [],
    }
  },
  computed: {
    today(){
      return new Date()
    }
  },
  methods: {
    isValidDate(dateString) {
      let regEx = /^\d{2}\/\d{2}\/\d{4}$/;
      // Check for the pattern
      if (!dateString.match(regEx)) return false;  // Invalid format
      let parts = dateString.split('/');
      let month = parseInt(parts[0], 10) - 1; // JavaScript months are 0-based
      let day = parseInt(parts[1], 10);
      let year = parseInt(parts[2], 10);
      let dateObj = new Date(year, month, day);
      // Check if the date object converted back matches the original parts to validate
      return dateObj.getFullYear() === year && dateObj.getMonth() === month && dateObj.getDate() === day;
    },
    isReasonableAge(birthday) {
      let parts = birthday.split('/');
      let birthYear = parseInt(parts[2], 10);
      let birthMonth = parseInt(parts[0], 10) - 1; // JavaScript months are 0-based
      let birthDay = parseInt(parts[1], 10);
      let birthDate = new Date(birthYear, birthMonth, birthDay);

      let today = new Date();
      let age = today.getFullYear() - birthYear;
      let m = today.getMonth() - birthMonth;

      if (m < 0 || (m === 0 && today.getDate() < birthDay)) {
        age--;
      }

      return age >= 0 && age <= 120;
    },
    isUnderEighteen(birthday) {
      let today = new Date();
      let birthDate = new Date(birthday);
      let age = today.getFullYear() - birthDate.getFullYear();
      let monthDifference = today.getMonth() - birthDate.getMonth();

      if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age < 18;
    },
    removeChild(index){
      this.minors.splice(index, 1);
    },
    getDayOfWeek(d){
      if(this.locale.show_day_of_week){
        return dayOfWeek(d) + ', '
      }
      return ''
    },
    getFormattedDate(format, d){
      return formattedDate(format, d)
    },
    getUserInfo() {
      return new Promise ( function(resolve) {
        Auth.currentUserInfo()
          .then(data =>{
            resolve(data)
          })
          .catch(err => console.log(err))
      })
    },
    addMinor(){
      this.minors.push(
        {
          fist_name: '',
          last_name: '',
          birthday: ''
        }
      )
      this.errs.child.push({
        fist_name: '',
        last_name: '',
        birthday: ''
      })
    },
    getSignature () {
      if(this.$refs.signaturePad == undefined){
        return
      }
      var png =  this.$refs.signaturePad.saveSignature().data
      if (this.$refs.signaturePad.isEmpty()) {
        return ''
      } else {
        return png
      }
    },
    clearSignature(){
      this.$refs.signaturePad.clearSignature()
    },
    setSignatureData(){
      this.signature = this.getSignature()
      let errors = 0

      if(this.signature == ''){
        errors++
        this.errs.signature = this.$t('checkin.errors.signature')
      } else {
        this.errs.signature = ""
      }
      if(this.waiverConfigurations.external_link != null && this.conditions == false){
        errors++
        this.errs.conditions = this.$t('checkin.errors.mustAcceptToS')
      } else {
        this.errs.conditions = ""
      }
      if(errors > 0){
        return
      }
    },
    getSource(){
      if(this.selectedSource === 'Other'){
        return this.otherSource.toLowerCase()
      } else {
        if(this.selectedSource.hasOwnProperty('value')){
          return this.selectedSource.value.toLowerCase()
        }
        return ''
      }
    },
    isBirthdayValid(){
      if(this.user.birthday == ''){return false}
      // if(parseInt(this.user.birthday.split('/')[0]) > 12 || parseInt(this.user.birthday.split('/')[0]) < 1){return false}
      // let dt = new Date()
      // dt.setFullYear(this.user.birthday.split('/')[2])
      // dt.setMonth(this.user.birthday.split('/')[0] - 1)
      // dt.setDate(this.user.birthday.split('/')[1])
      // console.log(dt)
    },
    validateFields(){
      //validation
      let errors = 0
      //Email
      if(this.isEmailValid(this.user.email) == false){
        errors++
        this.errs.email = this.$t('checkin.errors.email')
      } else {
        this.errs.email = ""
      }
      //User data
      if(this.user.first_name == '' && this.requiredFields.includes('First Name')){
        errors++
        this.errs.first_name = this.$t('checkin.errors.firstName')
      } else {
        this.errs.first_name = ""
      }
      if(this.user.last_name == '' && this.requiredFields.includes('Last Name')){
        errors++
        this.errs.last_name = this.$t('checkin.errors.lastName')
      } else {
        this.errs.last_name = ""
      }

      if((!this.isValidDate(this.user.birthday)
          || !this.isReasonableAge(this.user.birthday) || this.isUnderEighteen(this.user.birthday))
        && this.requiredFields.includes('Birthday')){
        errors++
        this.errs.birthday = this.$t('checkin.errors.birthday')
      } else if(this.user.birthday !== '' && (!this.isValidDate(this.user.birthday)
        || !this.isReasonableAge(this.user.birthday) || this.isUnderEighteen(this.user.birthday))) {
        this.errs.birthday = this.$t('checkin.errors.birthday')
      }else {
        this.errs.birthday = ""
      }

      if(this.user.phone == '' && this.requiredFields.includes('Phone')){
        errors++
        this.errs.phone = this.$t('checkin.errors.phone')
      } else {
        this.errs.phone = ""
      }

      //Children
      if(this.add_minors){
        for(let n in this.minors){
          if((this.minors[n].first_name === '' || !this.minors[n].first_name) && this.requiredFields.includes('Child First Name')){
            errors++
            this.errs.child[n].first_name = this.$t('checkin.errors.firstName')
          } else {
            this.errs.child[n].first_name = ""
          }

          if((this.minors[n].last_name === '' || !this.minors[n].last_name) && this.requiredFields.includes('Child Last Name')){
            errors++
            this.errs.child[n].last_name = this.$t('checkin.errors.lastName')
          } else {
            this.errs.child[n].last_name = ""
          }

          if(this.requiredFields.includes('Child Birthday') && (!this.minors[n].birthday || this.minors[n].birthday == '')){
            errors++
            this.errs.child[n].birthday = this.$t('checkin.errors.birthdayEmpty')
          } else if((this.minors[n].birthday != '') &&
            (!this.isValidDate(this.minors[n].birthday) || !this.isReasonableAge(this.minors[n].birthday))) {
            this.errs.child[n].birthday = this.$t('checkin.errors.birthday')
          } else {
            this.errs.child[n].birthday = ""
          }
        }
      }


      //Marketing
      if(this.selectedSource == '' && this.requiredFields.includes('Source')){
        errors++
        this.errs.source = this.$t('checkin.errors.required')
      } else {
        this.errs.source = ""
      }
      if(this.selectedExperience == '' && this.requiredFields.includes('Experience')){
        errors++
        this.errs.experience = this.$t('checkin.errors.required')
      } else {
        this.errs.experience = ""
      }
      if(this.selectedState == '' && this.requiredFields.includes('Location')){
        errors++
        this.errs.location.state =  this.$t('checkin.errors.required')
      } else {
        this.errs.location.state = ""
      }
      if(this.city == '' && this.requiredFields.includes('Location')){
        errors++
        this.errs.location.city =  this.$t('checkin.errors.required')
      } else {
        this.errs.location.city = ""
      }


      if(errors > 0){
        return false
      }
      return true
    },
    async confirmCheckin(){
      this.setSignatureData()
      if(!this.validateFields()){return}

      let b = this.user.birthday
      if(this.user.birthday != undefined || this.user.birthday != ''){
        b =  b.split('/')[2] + '-' + b.split('/')[0] + '-' + b.split('/')[1]
      }

      for(let n in this.minors){
        if(!this.minors[n].first_name && !this.minors[n].last_name){
          this.minors.splice(n, 1)
        }
      }
      for(let m in this.minors){
        if(this.minors[m].birthday != undefined || this.minors[m].birthday != ''){
          this.minors[m].birthday =  this.minors[m].birthday.split('/')[2] + '-' + this.minors[m].birthday.split('/')[0] + '-' + this.minors[m].birthday.split('/')[1]
        }
      }

      let bookings = []
      for (let n in this.bookings){
        if(this.bookings[n].included){
          bookings.push(this.bookings[n])
        }
      }

      this.loading = true
      let url = API_LOCATION + 'checkin/' + this.hash
      this.user.birthday = b
      let data = {
        company_id: this.waiverConfigurations.company_id,
        user: this.user,
        bookings: bookings,
        minors: this.minors,
        signature: this.signature,
        picture_optout: this.picture_optout,
        email_optout: this.email_optout,
        experience: this.selectedSource.hasOwnProperty('value') ? this.selectedSource.value.toLowerCase()  : '',
        location_city: this.city,
        location_state: this.selectedState,
        source: this.getSource(),
        not_participating: this.not_participating,
        locale: this.$i18n.locale
      }


      this.axios.post(url, data)
        .then(response => {
          this.loading = false
          this.disabled = true
          this.checked_in = true
          swal({
            title: this.$t('checkin.swals.congratulations'),
            text: this.$t('checkin.swals.allSet'),
            type: 'success',
            showCancelButton: false,
            confirmButtonColor: '#fd5d93',
            confirmButtonText: 'OK'
          })

        })
        .catch(err => {
          this.loading = false
          console.log(err)
          if(err.response.status == 401){
            this.$router.push('/forbidden')
          }
          if(err.response.data == 'Waiver record already exists'){
            swal({
              title: this.$t('checkin.swals.error'),
              text: this.$t('checkin.swals.alreadyCheckedIn'),
              type: 'error',
              showCancelButton: false,
              confirmButtonColor: '#fd5d93',
              confirmButtonText: 'OK'
            }).then((response)=> {
              this.$router.go()
            })
          }
        })
    },
    getTime(format, d){
      return time(format, d)
    },
    isEmailValid(emailString) {
      var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(emailString);
    },
    getWaiverConfigurations(){
      this.loading = true
      let url = API_LOCATION + 'checkin/' + this.hash

      axios.get(url)
        .then(response => {
          this.notFound = false
          this.waiverConfigurations = response.data.waiver_configurations
          this.bookings = response.data.bookings
          this.fields = response.data.fields
          this.sections = response.data.sections
          this.locale = response.data.locale
          for(let n in this.fields){
            if(this.fields[n].is_active == 1){
              this.display.push(this.fields[n].name)
              if(this.fields[n].is_required){
                this.requiredFields.push(this.fields[n].name)
              }
            }
          }
          for(let n in this.bookings){
            this.bookings[n].included = true
          }
          if(this.locale.country === 'GB'){
            this.phone_placeholder = '07700 900000'
            this.state_placeholder = this.$t('checkin.country')
          }
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.notFound = true
          console.log(err)
        })
    },

    login(){
      let redirect = '/checkin/' + this.$route.params.company
      localStorage.setItem('redirect', redirect)
      this.$router.push('/login')
    },
    register(){
      let redirect = '/checkin/' + this.$route.params.company
      localStorage.setItem('redirect', redirect)
      this.$router.push('/register')
    },
    toProfile(){
      this.$router.push('/settings/profile')
    },
    async isAuthenticated(){
      let token = await this.getAuthToken()
      if(token == 'No current user'){
        this.is_authenticated = false
      } else {
        this.is_authenticated = true
        this.user = JSON.parse(localStorage.getItem('user'))
      }
    },
    getAuthToken() {
      return new Promise(function (resolve) {
        Auth.currentSession()
          .then(data => {
            resolve(data.idToken.jwtToken)
          })
          .catch(err => {
            console.log(err)
          })
      })
    },
    async getUser(){
      let user = await this.getUserInfo()
      let redirect = '/checkin/' + this.$route.params.company
      if(user == null){
        localStorage.setItem('redirect', redirect)
      }
    }

  },
  mounted() {

    this.hash = this.$route.params.hash
    this.getWaiverConfigurations()
  }
}
</script>

<style scoped>
.game-button{
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;

}
.sig-pad {
  border: #344675 2px solid;
  border-radius: 5px;
}
textarea
{
  width:100%;
  margin:5px 0;
  padding:1%;
}


</style>
